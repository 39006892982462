@import "../../assets/styles/utils";

.index-banner{
    width: 100%;
    height: 100vh;
    .swiper-container{
        height: 100%;
        // .mob{
        //     display: none;
        // }
        // @media (max-width: 767px){
        //     .pc{
        //         display: none;
        //     }
        //     .mob{
        //         display: flex;
        //     }
        // }
        .swiper-slide{
            @include bg-cover;
            .pc{
                width: 100%;
                height: 100%;
                @include bg-cover;
            }
            .mob{
                display: none;
                width: 100%;
                height: 100%;
                @include bg-cover;
            }
            @media (max-width: 767px){
                .pc{
                    display: none;
                }
                .mob{
                    display: block;
                    // background-size: 100% 100%;
                    background-size: cover;
                    background-position: 50%;
                    background-repeat: no-repeat;
                }
            }
            .banner-text{
                position: fixed;
                width: 100%;
                top: 36%;
                color: #fff;
                z-index: 2;
                font-family: 'Lato-Light';
                overflow: hidden;
                line-height: 1.2;
                @include res(font-size,50px,(lg:40px, mmd:34px, md:30px,sm:24px,xs:18px));
                .container{
                    transition:all .6s;
                    transform: translateY(100%);
                }
            }
            &.swiper-slide-active{
                .banner-text{
                    .container{
                        transform: translateY(0);
                    }
                }
            }
        }
    }
    .swiper-control{
        position: fixed;
        width: 100%;
        top: 27%;
        color: #fff;
        z-index: 2;
        @include res(display,null,(xs:none));
        .container{
            display: flex;
            align-items: center;
            justify-content: space-between;
            .swiper-pagination{
                position: static;
                width: auto;
                flex-shrink: 0;
                margin-right: 10px;
                @include res(font-size,$text-size-lg-base,$text-size-lg-ratio);
                .swiper-pagination-current{
                    margin-right: 5px;
                }
                .swiper-pagination-total{
                    opacity: .5;
                    margin-left: 5px;
                }
            }
            .line{
                height: 1px;
                width: 100%;
                background-color: rgba(255,255,255,.3);
            }
            .swiper-btn{
                display: flex;
                align-items: center;
                justify-content: flex-end;
                flex-shrink: 0;
                margin-left: 10px;
                @include res(height,40px, 30 / 40);
                .swiper-button-next, .swiper-button-prev{
                    position: static;
                    border: 1px solid #fff;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: #fff;
                    margin-top: 0;
                    cursor: pointer;
                    @include res(width,40px, 30 / 40);
                    @include res(height,40px, 30 / 40);
                    &::after{
                        display: none;
                    }
                }
                .swiper-button-next{
                    @include res(margin-left,15px, 10 / 15);
                }
            }
        }
    }
}
.right-page{
    position: fixed;
    top: 50%;
    color: #fff;
    transform: translateY(-50%);
    z-index: 5;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    @include res(right,2%,(sm:0.5%));
    @include res(display,flex,(xs:none));
    .page-prev,.page-next{
        cursor: pointer;
        transition: all $anime-duration;
        opacity: .5;
        &:hover{
            opacity: 1;
        }
    }
    .page-title{
        overflow: hidden;
        writing-mode:tb-rl;
        text-transform: uppercase;
        font-size: 12px;
        @include res(margin-top,20px,10 / 20);
        @include res(margin-bottom,20px,10 / 20);
        width: 22px;
        overflow: hidden;
        .cont{
            transition: all $anime-duration;
            P{
                transform: rotate(180deg);
                width: 22px;
                text-align: center;
            }
        }
    }
}
.page-swiper{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    box-sizing: border-box;
    z-index: 1;
    @include res(padding-top,$header-height-base,$header-height-ratio);
    @include res(padding-left,160px, 0 / 160);
    @include res(padding-right,160px, 0 / 160);
    @include res(padding-bottom,100px, 50 / 100);
    @media screen and (max-width:1024px) {
        @include res(padding-top,31vh);
        @include res(padding-bottom,31vh);
    }
    @media screen and (max-width:992px) {
        @include res(padding-top,20vh);
        @include res(padding-bottom,20vh);
    }
    @media screen and (max-width:767px) {
        @include res(padding-top,$header-height-base,$header-height-ratio);
        @include res(padding-bottom,100px, 50 / 100);
    }
    &.show-all{
        .swiper-container{
            border-radius: 0;
        }
    }
    .swiper-pagination{
        position: fixed;
        top: 50%;
        z-index: 5;
        transform: translateY(-50%);
        flex-direction: column;
        @include res(left,2%,(sm:1%));
        @include res(display,flex,(xs:none));
        .swiper-pagination-bullet{
            width: 20px;
            padding: 8px 0;
            height: auto;
            opacity: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: transparent;
            &::after{
                content: '';
                display: block;
                width: 5px;
                height: 5px;
                border-radius: 5px;
                background-color: #fff;
                opacity: .5;
                transition: all $anime-duration;
            }
            &.swiper-pagination-bullet-active{
                &::after{
                    height: 30px;
                    opacity: 1;
                }
            }
        }
    }
    .swiper-copyright{
        position: fixed;
        width: 100%;
        bottom: 0;
        left: 0;
        z-index: 4;
        @include res(height,100px, 50 / 100);
        @include res(padding-left,190px, 10 / 190);
        @include res(padding-right,190px, 10 / 190);
        @media screen and (max-width:1024px) {
            bottom: calc(30vh - 70px);
        }
        @media screen and (max-width:992px) {
            bottom: calc(20vh - 60px);
        }
        @media screen and (max-width:767px) {
            bottom: 0;
        }
        .cont{
            height: 100%;
            color: #fff;
            align-items: center;
            display: flex;
            @include res(font-size,null,(xs:.24rem));
            @include res(justify-content,space-between,(xs:center));
            @include res(line-height,1.5,1 / 1.5);
            @include res(text-align,null,(xs:center));
            @include res(flex-direction,row,(xs:column));
            @include res(padding-top,20px, 0 / 20);
            @include res(padding-bottom,20px, 0 / 20);
            @include res(margin-left,null,(sm:-20px,xs:0));
            @include res(margin-right,null,(sm:-20px,xs:0));
            .beian{
                @include res(margin-bottom,null,(xs:.1rem));
                // a,span{
                //     @include res(display,null,(xs:block));
                // }
            }
            span{
                margin-left: 10px;
                margin-right: 10px;
                // white-space: nowrap;
                // @include res(margin-right,10px,(xs:0));
            }
            .beian+div{
                a:first-child,a:first-child+span{
                    @include res(display,null,(md:none));
                }
            }
        }
    }
    .swiper-bottom{
        position: fixed;
        width: 100%;
        bottom: 0;
        left: 0;
        z-index: 5;
        transition: all $anime-duration;
        @include res(height,100px, 50 / 100);
        @include res(padding-left,160px, 0 / 160);
        @include res(padding-right,160px, 0 / 160);
        @media screen and (max-width:1024px) {
            bottom: calc(30vh - 70px);
        }
        @media screen and (max-width:992px) {
            bottom: calc(20vh - 60px);
        }
        @media screen and (max-width:767px) {
            bottom: 0;
        }
        .cont{
            height: 100%;
            background: #252d67;
            display: flex;
            align-items: center;
            justify-content: space-between;
            position: relative;
            color: #fff;
            transition: all $anime-duration;
            @include res(padding-left,75px, 10 / 75);
            @include res(padding-right,75px, 10 / 75);
            .left{
                display: flex;
                align-items: center;
                justify-content: flex-start;
                .yuan{
                    border-radius: 50%;
                    border-style: solid;
                    border-color: #5fb4ef;
                    transition: all $anime-duration;
                    @include res(width,12px, 10 / 12);
                    @include res(height,12px, 10 / 12);
                    @include res(border-width,3px, 2 / 3);
                }
                .text{
                    line-height: 28px;
                    height: 28px;
                    overflow: hidden;
                    @include res(margin-left,10px, 5 / 10);
                    .text-cont{
                        font-family: 'Lato-Bold';
                        transition: all $anime-duration;
                        @include res(font-size,$text-size-lg-base,$text-size-lg-ratio);
                        p{
                            height: 28px;
                            text-transform: capitalize;
                            &:nth-child(1){
                                color: #fff;
                            }
                            &:nth-child(2){
                                color: #333333;
                            }
                            &:nth-child(3){
                                color: #fff;
                            }
                            &:nth-child(4){
                                color: #333333;
                            }
                            &:nth-child(5){
                                color: #fff;
                            }
                        }
                    }
                }
            }
            .center{
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate3d(-50%,-50%,0);
                border-radius: 50%;
                background: #fff;
                display: flex;
                align-items: center;
                justify-content: center;
                transition: all $anime-duration;
                @include res(width,42px, 35 / 42);
                @include res(height,42px, 35 / 42);
                .outer-line{
                    width: 12px;
                    height: 19px;
                    border-radius: 10px;
                    border: 1px solid #5e5e5e;
                    position: relative;
                    overflow: hidden;
                    .inner-line{
                        width: 3px;
                        height: 6px;
                        border-radius: 3px;
                        border: 1px solid #5e5e5e;
                        position: absolute;
                        top: 3px;
                        left: 50%;
                        transform: translateX(-50%);
                        animation: rain 1s infinite linear;
                    }
                }
            }
            .right{
                opacity: .6;
                @include res(font-size,$text-size-base,$text-size-ratio);
            }
        }
    }
    .swiper-container{
        height: 100%;
        transition: all $anime-duration;
        @include res(border-top-left-radius,80px, 30 / 80);
        .swiper-aside{
            position: absolute;
            top: 0;
            left: 0;
            z-index: 4;
            box-sizing: border-box;
            opacity: 0;
            transition: all $anime-duration;
            align-items: center;
            justify-content: flex-start;
            @include res(padding-left,75px, 20 / 75);
            @include res(padding-top,90px, 20 / 90);
            @include res(width,24%,(sm:100%));
            @include res(height,100%,(sm:50px));
            @media screen and (max-width:991px) {
                height:76px;
            }
            @media screen and (max-width:771px) {
                @include res(height,100%,(sm:50px));
            }
            @include res(display,null,(sm:flex));
            .title{
                position: relative;
                font-family: 'Lato-Bold';
                @include res(font-size,$title-size-lg-base,$title-size-lg-ratio);
                .dian{
                    content: '';
                    display: block;
                    border-radius: 50%;
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    background: #5fb4ef;
                    transition: all $anime-duration;
                    @include res(left,-22px, -10 / -22);
                    @include res(width,8px,5 / 8);
                    @include res(height,8px,5 / 8);
                }
                .title-show-cont{
                    overflow: hidden;
                    @include res(height,40px,24 / 40);
                    @include res(line-height,40px,24 / 40);
                    .title-cont{
                        transition: all $anime-duration;
                        p{
                            text-transform: uppercase;
                            @include res(height,40px,24 / 40);
                            &:nth-child(2){
                                color: #5fb4ef;
                            }
                            &:nth-child(3){
                                color: #333333;
                            }
                            &:nth-child(4){
                                color: #252d67;
                            }
                            &:nth-child(5){
                                color: #333333;
                            }
                        }
                    }
                }
            }
            .number{
                overflow: hidden;
                position: relative;
                font-family: 'DIN';
                line-height: 1;
                @include res(margin-left,0,(sm:20px,xs:10px));
                @include res(height,80px,24 / 80);
                @include res(line-height,80px,24 / 80);
                @include res(font-size,80px,20 / 80);
                .number-cont{
                    transition: all $anime-duration;
                    p{
                        @include res(height,80px,24 / 80);
                        &:nth-child(2){
                            color: #515785;
                        }
                        &:nth-child(3){
                            color: #cccccc;
                        }
                        &:nth-child(4){
                            color: #6bb2e4;
                        }
                        &:nth-child(5){
                            color: #cccccc;
                        }
                    }
                }
            }
            .more-link{
                position: absolute;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                @include res(bottom,90px, 20 / 90);
                .text{
                    writing-mode:tb-rl;
                    transform: rotate(180deg);
                    font-size: 14px;
                    font-family: 'DIN';
                    transition: all $anime-duration;
                    @include res(display,null,(sm:none));
                }
                .icon{
                    border-radius: 50%;
                    background: #469fdd;
                    color: #fff;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    transition: all $anime-duration;
                    @include res(width,57px, 35 / 57);
                    @include res(height,57px, 35 / 57);
                    @include res(margin-top,17px, 10 / 17);
                    &:hover{
                        .iconfont{
                            transform: rotate(90deg);
                        }
                    }
                    .iconfont{
                        transition: all $anime-duration;
                        @include res(font-size,26px, 20 / 26);
                    }
                }
            }
        }
        .swiper-wrapper{
            transition: all cubic-bezier(0.13, 0.63, 0.6, 0.98);
        }
        .swiper-slide{
            overflow: hidden;
            box-sizing: border-box;
            justify-content: center;
            flex-direction: column;
            @include res(display,null,(sm:flex));
            @include res(padding-top,90px, 20 / 90);
            @include res(padding-right,75px, 10 / 75);
            @media screen and (max-width: 1600px){
                @include res(padding-top,50px, 20 / 50);
            }
            &:not(:last-child){
                @include res(padding-left,24%, (sm:20px,xs:10px));
            }
            &:nth-child(1){
                opacity: 0;
                visibility: hidden;
                background-color: transparent;
            }
            &:nth-child(2){
                background-color: #252d67;
                @include res(padding-bottom,0px,(sm:20px, xs:10px));
            }
            &:nth-child(3){
                background-color: #fff;
                @include res(padding-bottom,90px, 10 / 90);
                @media screen and (max-width: 1600px){
                    @include res(padding-bottom,50px, 10 / 50);
                }
            }
            &:nth-child(4){
                background-color: #469fdd;
                @include res(padding-bottom,90px, 10 / 90);
                @media screen and (max-width: 1600px){
                    @include res(padding-bottom,50px, 10 / 50);
                }
            }
            &:nth-child(5){
                background-color: #fff;
                @include res(padding-bottom,90px, 10 / 90);
                @media screen and (max-width: 1600px){
                    @include res(padding-bottom,50px, 10 / 50);
                }
            }
            &:nth-child(6){
                background-color: #252d67;
                @include res(padding-left,75px, 10 / 75);
            }
        }
    }
}
@media screen and (max-width: 768px) {
    .page-swiper .swiper-container .swiper-aside{
        padding-top: 10px;
        // @include res(padding-top,90px, 70 / 90)
    }
    .page-swiper .swiper-container .swiper-aside .more-link{
        bottom: 0;
        right: 10px;
    }
    .page-swiper .swiper-container .swiper-slide{
        // padding-top: 10px;
        @include res(padding-top,90px, 60 / 90)
    }
}

.index-products{
    .icon-list{
        ul{
            display: flex;
            align-items: stretch;
            justify-content: flex-start;
            flex-wrap: wrap;
            position: relative;
            li{
                flex-shrink: 0;
                transition: all $anime-duration;
                @include res(width,33.33%);
                @include res(height,380px, 200 / 380);
                cursor: pointer;
                position: relative;
                overflow: hidden;
                @media screen and (max-width: 1600px){
                    @include res(height,320px, 200 / 320);
                }
                @media screen and (max-width: 1024px){
                    @include res(height,380px, 200 / 380);
                }
                >a{
                    display: block;
                    overflow: hidden;
                }
                &:hover{
                    .bc{transform: scale(1.2);}
                }
                a{
                    display: block;
                    height: 100%;
                    box-sizing: border-box;
                    font-family: 'Lato-Light';
                    color: #fff;
                    @include res(padding-top,40px, 15 / 40);
                    @include res(padding-bottom,40px, 15 / 40);
                    @include res(padding-left,50px, 20 / 50);
                    @include res(font-size,24px,(lg:22px, mmd:20px, md:16px));
                    span{
                        display: block;
                        position: relative;
                        &::after{
                            content: '';
                            display: block;
                            position: absolute;
                            top: 50%;
                            background-color: #fff;
                            transition: all $anime-duration;
                            transform: translateY(-50%) scaleX(0);
                            transform-origin: right;
                            @include res(left,-50px, -20 / -50);
                            @include res(width,40px, 10 / 40);
                            @include res(height,2px, 1 / 2);
                        }
                    }
                    .bac{
                        position: absolute;
                        width: 100%;
                        top: 0;
                        left: 0;
                        height: 30%;
                        max-height: 253px;
                        background-size: 100% 100%;
                        background-repeat: no-repeat;
                        z-index: 1;
                    }
                    .bc{
                        transition: all .8s;
                        background-repeat: no-repeat;
                        background-size: cover;
                        background-position: bottom center;
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        @include res(height,380px, 200 / 380);
                        top: 0;
                        left: 0;
                    }
                    .ti1{
                        z-index:2;
                        color: #ffffff;
                        font-family: Lato-Bold;
                        position: relative;
                    }
                    .ti2{
                        width: auto;
                        height: auto;
                        position: relative;
                        @include res(max-width,170px,(md:100px,xs:70px));
                        @include res(max-height,90px,(md:60px,xs:40px));
                        @include res(margin-top,40px, 15 / 40);
                    }
                    .ti3{
                        max-width: 50%;
                        height: auto;
                        position: absolute;
                        bottom: 0;
                        left: 0;
                    }
                }
               
            }
        }
    }
    .procont{
        width: 100%;
        border-bottom: 1px solid #2d4883;
        @include res(margin-top,30px,25 / 30);
        @include res(margin-bottom,40px,25 / 40);
        @include res(padding-bottom,0px);
        @media screen and (max-width: 1600px){
            @include res(margin-top,10px);
            @include res(margin-bottom,20px);
        }
        p{
            color: #fff;
            @include res(font-size,28px,(lg:26px, mmd:22px, md:18px));
            font-family: Lato-Light;
        }
    }
    .search{
        .title{
            color: #fff;
            font-family: 'Lato-Light';
            border-bottom: 1px solid rgba(70,159,221,.24);
            @include res(font-size,28px,(lg:26px, mmd:22px, md:18px));
            @include res(margin-top,50px,20 / 50);
            @include res(margin-bottom,45px,10 / 45);
            @include res(padding-bottom,10px,5 / 10);
        }
        .form-cont{
            align-items: center;
            justify-content: space-between;
            // @include res(margin-top,100px,50 / 100);
            @include res(display,flex,(xs:block));
            .left-tab{
                flex-shrink: 0;
                display: flex;
                align-items: center;
                justify-content: space-between;
                // @include res(width,210px, 150 / 210);
                @include res(margin-right,25px, 15 / 25);
                @include res(margin-bottom,0,(xs:10px));
                div{
                    position: relative;
                    font-family: 'Lato-Bold';
                    color: rgba(255,255,255,.8);
                    cursor: pointer;
                    @include res(padding-left,30px, 20 / 30);
                    @include res(font-size,$text-size-lg-base,$text-size-lg-ratio);
                    &.active{
                        &::after{
                            display: block;
                        }
                    }
                    &::after{
                        content: '';
                        display: none;
                        width: 6px;
                        height: 6px;
                        border-radius: 50%;
                        background: #469fdd;
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        @include res(left,12px, 7 / 12);
                    }
                }
            }
            .right-form{
                width: 100%;
                display: flex;
                justify-content: space-between;
                .select{
                    flex-shrink: 0;
                    @include res(width,62%);
                    .select-item{
                        position: relative;
                        width: 100%;
                        height: 100%;
                        display: none;
                        @include res(font-size, $text-size-md-base, $text-size-md-ratio);
                        &.active{
                            input{
                                border-top-left-radius: 0;
                                border-top-right-radius: 0;
                            }
                        }
                        &::after {
                            content: '\e62d';
                            font-family: 'iconfont' !important;
                            position: absolute;
                            top: 50%;
                            transform: translateY(-50%);
                            display: block;
                            z-index: 1;
                            color: #5fb4ef;
                            @include res(font-size,24px, 20 / 24);
                            @include res(right,10px,(xs:0px));
                        }
                        input {
                            box-sizing: border-box;
                            width: 100%;
                            outline: none;
                            background: none;
                            overflow: hidden;
                            width: 100%;
                            height: 100%;
                            color: rgba(255,255,255,.8);
                            cursor: pointer;
                            border: 1px solid #5fb4ef;
                            border-radius: 5px;
                            @include res(padding-left,25px,15 / 25);
                            @include res(padding-right,15px,(xs:15px));
                            &::-webkit-input-placeholder {
                                color: rgba(255,255,255,.8);
                            }
                            &:-moz-placeholder {
                                color: rgba(255,255,255,.8);
                            }
                            &::-moz-placeholder {
                                color: rgba(255,255,255,.8);
                            }
                            &:-ms-input-placeholder {
                                color: rgba(255,255,255,.8);
                            }
                        }
                        .selet-list{
                            position: absolute;
                            bottom: calc(100% - 1px);
                            left: 0;
                            width: 100%;
                            border: 1px solid #5fb4ef;
                            border-top-left-radius: 5px;
                            border-top-right-radius: 5px;
                            background-color: #252d67;
                            color: rgba(255,255,255,.8);
                            display: none;
                            overflow-y: auto;
                            @include res(max-height,600px,(sm:300px));
                            // @include res(overflow-y,null,(sm:auto));
                            ul{
                                li{
                                    transition: all $anime-duration;
                                    cursor: pointer;
                                    @include res(padding-left,25px,15 / 25);
                                    @include res(padding-right,25px,15 / 25);
                                    @include res(padding-top,10px,(lg:5px));
                                    @include res(padding-bottom,5px,5 / 5);
                                    &:hover{
                                        background-color: #348fce;
                                    }
                                }
                            }
                        }
                    }
                }
                .button{
                    flex-shrink: 0;
                    @include res(width,36.2%);
                    @include res(height,60px, 40 / 60);
                    button{
                        width: 100%;
                        height: 100%;
                        background-color: #469fdd;
                        outline: none;
                        border: none;
                        color: #fff;
                        cursor: pointer;
                        font-family: 'Lato-Bold';
                        transition: all $anime-duration;
                        @include res(font-size,$text-size-lg-base,$text-size-lg-ratio);
                        span{
                            @include res(display,null,(xs:none));
                        }
                        &:hover{
                            background-color: #348fce;
                        }
                    }
                }
            }
        }
    }
}

.index-technology{
    ul{
        // height: 100%;
        display: flex;
        flex-wrap: wrap;
        position: relative;
        border-top: 2px solid #469fdd;
        border-left: 1px solid #ededed;
        @include res(height,100%,(sm:80%,xs:auto));
        li{
            border-right: 1px solid #ededed;
            border-bottom: 1px solid #ededed;
            position: relative;
            @include res(width,33.33%,(md:33.33%,xs:100%));
            @include res(padding-left,40px, 10 / 40);
            @include res(padding-right,40px, 10 / 40);
            @include res(padding-top,58px, 10 / 58);
            @include res(padding-bottom,80px, 10 / 80);
            &:nth-child(1),&:nth-child(2){
                @include res(height,100%,(md:100%,xs:auto));
            }
            &:nth-child(3),&:nth-child(4){
                // height: 50%;
                @include res(height,50%,(xs:auto));
                .item-img{
                    @include res(display,none,(xs:block));
                }
                .item-link{
                    @include res(bottom,30px, 10 / 30);
                    @media (max-width: 1600px){
                        bottom: 10px;
                    }
                }
                @media (max-width: 767px){
                    display: none;
                }
            }
            &:nth-child(4){
                bottom: 0;
                right: 0;
                @include res(position,absolute,(xs:relative));
            }
            &:hover{
                .item-img{
                    img{
                        transform: scale(1.05);
                    }
                }
            }
            .item-img{
                overflow: hidden;
                @include res(display,block);
                @include res(margin-bottom,35px, 10 / 35);
                @include res(height,null,(xs:100px));
                @include res(max-height,260px,(lg:150px,xs:100px));
                display: flex;
                align-items: center;
                justify-content: center;
                img{
                    display: block;
                    height: auto;
                    transition: all $anime-duration;
                    @include res(margin,null,(xs:0 auto));
                    @include res(width,100%);
                }
            }
            .item-title{
                font-family: 'Lato-Light';
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                line-height: 1.2;
                @include res(text-align,null,(xs:center));
                @include res(margin-bottom,10px, 5 / 10);
                @media (max-width: 1024px){
                    margin-bottom: 4px;
                }
            }
            .item-desc{
                font-family: 'Lato-Bold';
                @media (max-width: 1024px){
                    line-height: 1.1;
                }
                @media (max-width: 991px){
                    line-height: 1.5;
                }
                display: -webkit-box;    
                -webkit-box-orient: vertical;    
                overflow: hidden;
                @include res(line-height,1.5,(xs:1.2));
                @include res(-webkit-line-clamp,3,(xs:2));
                @media (max-width: 1024px){
                    -webkit-line-clamp:2;
                }
                @include res(font-size,$text-size-md-base,$text-size-md-ratio);
                &.minheight{
                    -webkit-line-clamp:2 !important;
                }
            }
            .item-link{
                position: absolute;
                left: 0;
                width: 100%;
                align-items: center;
                justify-content: space-between;
                color: #0082d0;
                @include res(display,flex,(xs:none));
                @include res(bottom,50px, 0 / 50);
                @media (max-width: 1600px){
                    bottom: 20px;
                }
                @media (max-width: 1024px){
                    bottom: 0;
                }
                @media (max-width: 991px){
                    @include res(bottom,50px, 0 / 50);
                }
                @include res(padding-left,40px, 10 / 40);
                @include res(padding-right,40px, 10 / 40);   
                &:hover{
                    .icon{
                        transform: rotate(90deg);
                    }
                }
                .text{
                    font-size: 14px;
                    font-family: 'Lato-Bold';
                }
                .icon{
                    transition: all $anime-duration;
                    .iconfont{
                        font-size: 18px;
                    }
                }
            }
        }
    }
}
@media screen and (width:1366px){
    .index-technology ul li{
        padding-top: 20px;
    }
}
@media screen and (width:768px){
    .index-technology{
        padding-bottom: 0px;
    }
}

.index-services{
    ul{
        display: flex;
        // flex-wrap: wrap;
        align-items: stretch;
        justify-content: space-between;
        @include res(display,flex,(xs:block));
        @include res(height,100%,(sm:80%,xs:auto));
        li{
            border: 1px solid #fff;
            position: relative;
            transition: all $anime-duration;
            @include res(width,50%,(xs:100%));
            @include res(height,100%,(xs:auto));
            @include res(padding-left,30px, 10 / 30);
            @include res(padding-right,30px, 10 / 30);
            @include res(padding-bottom,30px, 10 / 30);
            @include res(padding-top,60px, 10 / 60);
            @media (max-width: 1600px){
                @include res(padding-top,40px, 10 / 40);
            }
            &:hover{
                background-color: #fff;
                .item-desc{
                    color: $color-main;
                }
                .item-img{
                    img{
                        transform: scale(1.05);
                    }
                }
            }
            &:first-child{
                @include res(border-right,none,(xs:1px solid #fff));
                @include res(border-bottom,1px solid #fff,(xs:none));
            }
            .item-title{
                color: #252d67;
                font-family: 'Lato-Light';
                @include res(text-align,null,(xs:center));
                @include res(font-size,$title-size-sm-base,$title-size-sm-ratio);
                @include res(margin-bottom,45px, 5 / 45);
                @media (max-width: 1600px){
                    @include res(margin-bottom,25px, 5 / 25);
                }
            }
            .item-desc{
                color: #fff;
                font-family: 'Lato-Bold';
                display: -webkit-box;    
                -webkit-box-orient: vertical;    
                overflow: hidden;
                // -webkit-line-clamp:3;
                transition: all $anime-duration;
                @include res(line-height,null,(xs:1.2));
                @include res(-webkit-line-clamp,3,(xs:2));
                @media (max-width: 1024px){
                    -webkit-line-clamp:2;
                }
                @include res(font-size,$text-size-md-base,$text-size-md-ratio);
                &.minheight{
                    -webkit-line-clamp:2 !important;
                }
            }
            .item-img{
                overflow: hidden;
                @include res(margin-top,0,(xs:10px));
                @include res(display,block);
                @include res(position,absolute,(xs:static));
                @include res(bottom,30px, 10 / 30);
                @include res(left,30px, 10 / 30);
                @include res(right,30px, 10 / 30);
                img{
                    display: block;
                    // width: 100%;
                    height: auto;
                    @media screen and (max-width:1024px) {
                        height:105px;
                    }
                    @media screen and (max-width:991px) {
                        height: auto;
                    }
                    transition: all $anime-duration;
                    @include res(margin,null,(xs:0 auto));
                    @include res(width,100%,(xs:auto));
                    @include res(max-height,260px,(xs:100px));
                }
            }
        }
    }
}
@media screen and (width:1366px){
    .index-services ul li{
        padding-top: 20px;
    }
    .index-services ul li .item-title{
        margin-bottom: 20px;
    }
}

.index-about{
    .cont{
        position: relative;
        @include res(height,100%,(sm:80%,xs:auto));
        &::after{
            content: '';
            width: 36.4%;
            height:100%;
            background: #252d67;
            position: absolute;
            left: 0;
            top: 0;
            z-index: 0;
            @include res(display,block,(xs:none));
        }
        .cont1{
            align-items: flex-start;
            justify-content: space-between;
            position: relative;
            z-index: 1;
            @include res(display,flex,(xs:block));
            .left{
                flex-shrink: 0;
                box-sizing: border-box;
                background: #252d67;
                @include res(padding,30px, 10 / 30);
                @include res(width,36.4%,(xs:100%));
                div{
                    font-family: 'Lato-Light';
                    line-height: 1.2;
                    background: url(./images/about_img1.png) no-repeat right bottom #fff;
                    @include res(background-size,null,(md:auto 80%));
                    @include res(padding-top,60px, 20 / 60);
                    @include res(padding-bottom,60px, 20 / 60);
                    @include res(padding-left,25px, 15 / 25);
                    @include res(font-size,$title-size-xs-base,$title-size-xs-ratio);
                }
            }
            .right{
                flex-shrink: 0;
                box-sizing: border-box;
                @include res(margin-top,0,(xs:15px));
                @include res(margin-bottom,0,(xs:15px));
                @include res(padding,40px, 10 / 40);
                @include res(padding-top,30px, 10 / 30);
                @include res(width,63.6%,(xs:100%));
                .desc{
                    font-family: 'Lato-Light';
                    color: $color-text;
                    transition: all $anime-duration;
                    line-height: 1.1;
                    @include res(margin-bottom,20px, 10 / 20);
                }
                .title{
                    font-family: 'Lato-Light';
                    line-height: 1.2;
                    display: -webkit-box;    
                    -webkit-box-orient: vertical;    
                    -webkit-line-clamp: 2;    
                    overflow: hidden;
                    @include res(margin-bottom,25px, 10 / 25);
                    @include res(font-size,$title-size-md-base,$title-size-md-ratio);
                }
                .link{
                    border-top: 1px dashed #cccccc;
                    a{
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        font-family: 'Lato-Bold';
                        color: #469fdd;
                        text-transform: uppercase;
                        @include res(font-size,14px);
                        @include res(padding-top,15px, 10 / 15);
                        @include res(padding-bottom,15px, 10 / 15);
                        .iconfont{
                            transition: transform $anime-duration;
                            @include res(font-size,18px,14 / 18);
                        }
                        &:hover{
                            .iconfont{
                                transform: rotate(90deg);
                            }
                        }
                    }
                }
            }
        }
        .cont2{
            position: relative;
            z-index: 1;
            img{
                display: block;
                margin: 0 auto;
                width: 100%;
                height: auto;
                max-width: 1066px;
            }
        }
    }
}

.index-contact{
    position: relative;
    color: #fff;
    &::before{
        content: '';
        display: block;
        width: 60%;
        height: 60%;
        background-color: rgba(70,159,221,.24);
        position: absolute;
        top: 20%;
        left: 0;
        z-index: 0;
        border-top-right-radius: 250px;
        border-bottom-right-radius: 250px;
    }
    &::after{
        content: '';
        display: block;
        height: 52%;
        background-color: rgba(70,159,221,.86);
        position: absolute;
        bottom: 0;
        right: 0;
        z-index: 0;
        border-top-left-radius: 250px;
        border-bottom-left-radius: 250px;
        @include res(width,50%,(xs:100%));
    }
    .cont{
        height: 100%;
        align-items: flex-start;
        justify-content: space-between;
        position: relative;
        z-index: 1;
        @include res(display,flex,(xs:block));
        .left{
            flex-shrink: 0;
            @include res(display,block,(xs:none));
            @include res(width,47%);
            ul{
                display: flex;
                flex-wrap: wrap;
                li{
                    @include res(width,50%,(xs:100%));
                    @include res(margin-bottom,45px, 15 / 45);
                    .menu-title{
                        font-family: 'Lato-Light';
                        @include res(font-size,$title-size-md-base,$title-size-md-ratio);
                        @include res(margin-bottom,25px, 10 / 25);
                    }
                    .sub-menu{
                        line-height: 1.6;
                        a{
                            display: block;
                            font-family: 'Lato-Bold';
                            transition: all $anime-duration;
                            @include res(font-size,$text-size-md-base,$text-size-md-ratio);
                            &:hover{
                                margin-left: 5px;
                            }
                        }
                    }
                }
            }
        }
        .right{
            flex-shrink: 0;
            @include res(width,50%,(xs:100%));
            .logo-img{
                width: 100%;
                height: auto;
                position: relative;
                @include res(right,-75px, -10 / -75);
                @include res(margin-top,20px, 10 / 20);
                @include res(margin-bottom,20px, 10 / 20);
            }
            .language{
                float: right;
                background: #333a71;
                border-radius: 5px;
                position: relative;
                z-index: 2;
                @include res(display,block);
                @include res(padding-left,15px, 10 / 15);
                @include res(padding-right,15px, 10 / 15);
                @include res(padding-top,5px, 5 / 5);
                @include res(padding-bottom,5px, 5 / 5);
                &:hover{
                    border-bottom-right-radius: 0px;
                    border-bottom-left-radius: 0px;
                    .sub-language{
                        opacity: 1;
                        visibility: visible;
                    }
                }
                .icon-yuyan{
                    @include res(font-size,22px, 16 / 22);
                }
                span{
                    font-family: 'Lato-Bold';
                    @include res(font-size,$text-size-md-base,$text-size-md-ratio);
                    @include res(margin-left,10px, 10 / 10);
                    @include res(margin-right,20px, 10 / 20);
                }
                .sub-language{
                    position: absolute;
                    top: 100%;
                    right: 0;
                    width: 100%;
                    background: #333a71;
                    text-align: center;
                    border-bottom-right-radius: 5px;
                    border-bottom-left-radius: 5px;
                    overflow: hidden;
                    opacity: 0;
                    visibility: hidden;
                    transition: all $anime-duration;
                    @include res(font-size,$text-size-md-base,$text-size-md-ratio);
                    a{
                        display: block;
                        transition: all $anime-duration;
                        @include res(padding-top,10px, 5 / 10);
                        @include res(padding-bottom,10px, 5 / 10);
                        &:hover{
                            background: rgba(255,255,255,.5);
                            color: $color-main-dark;
                        }
                    }
                }
            }
            .contact{
                position: absolute;
                bottom: 16%;
                z-index: 1;
                // @include res(margin-top,200px, 160 / 200);
                @include res(margin-left,190px, 50 / 190);
                @media screen and (max-width: 1600px){
                    bottom: 7%;
                    @include res(margin-left,100px, 50 / 100);
                }
                @media screen and (max-width: 767px){
                    @include res(margin-left,10px);
                }
                .title{
                    font-family: 'Lato-Bold';
                    margin-bottom: 5px;
                    @include res(font-size,$text-size-md-base,$text-size-md-ratio);
                }
                .email{
                    font-family: 'Lato-Light';
                    @include res(margin-bottom,45px, 20 / 45);
                    @include res(font-size,$title-size-xs-base,$title-size-xs-ratio);
                    @media screen and (max-width: 1600px){
                        @include res(margin-bottom,20px, 20 / 20);
                    }
                }
                .tel{
                    font-family: 'Lato-Light';
                    line-height: 1.2;
                    @include res(font-size,36px,(lg:32px, mmd:28px, md:20px));
                    @include res(margin-bottom,25px, 10 / 25);
                }
                .link{
                    display: flex;
                    align-items: center;
                    @include res(justify-content,flex-start,(xs:center));
                    a{
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 50%;
                        background-color: #fff;
                        color: $color-main-dark;
                        transition: all $anime-duration;
                        @include res(width,55px, 40 / 55);
                        @include res(height,55px, 40 / 55);
                        @include res(margin-right,25px, 15 / 25);
                        &:last-child{
                            margin-right: 0;
                        }
                        &:hover{
                            background-color: $color-main-dark;
                            color: #fff;
                        }
                        .iconfont{
                            @include res(font-size,24px, 18 / 24);
                        }
                    }
                }
            }
        }
    }
}