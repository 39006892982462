.index-banner {
  width: 100%;
  height: 100vh;
}

.index-banner .swiper-container {
  height: 100%;
}

.index-banner .swiper-container .swiper-slide {
  background-size: cover;
  background-position: center center;
}

.index-banner .swiper-container .swiper-slide .pc {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center center;
}

.index-banner .swiper-container .swiper-slide .mob {
  display: none;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center center;
}

@media (max-width: 767px) {
  .index-banner .swiper-container .swiper-slide .pc {
    display: none;
  }
  .index-banner .swiper-container .swiper-slide .mob {
    display: block;
    background-size: cover;
    background-position: 50%;
    background-repeat: no-repeat;
  }
}

.index-banner .swiper-container .swiper-slide .banner-text {
  position: fixed;
  width: 100%;
  top: 36%;
  color: #fff;
  z-index: 2;
  font-family: 'Lato-Light';
  overflow: hidden;
  line-height: 1.2;
  font-size: 50px;
}

@media (max-width: 1600px) {
  .index-banner .swiper-container .swiper-slide .banner-text {
    font-size: 40px;
  }
}

@media (max-width: 1359px) {
  .index-banner .swiper-container .swiper-slide .banner-text {
    font-size: 34px;
  }
}

@media (max-width: 1024px) {
  .index-banner .swiper-container .swiper-slide .banner-text {
    font-size: 30px;
  }
}

@media (max-width: 991px) {
  .index-banner .swiper-container .swiper-slide .banner-text {
    font-size: 24px;
  }
}

@media (max-width: 767px) {
  .index-banner .swiper-container .swiper-slide .banner-text {
    font-size: 18px;
  }
}

.index-banner .swiper-container .swiper-slide .banner-text .container {
  transition: all .6s;
  transform: translateY(100%);
}

.index-banner .swiper-container .swiper-slide.swiper-slide-active .banner-text .container {
  transform: translateY(0);
}

.index-banner .swiper-control {
  position: fixed;
  width: 100%;
  top: 27%;
  color: #fff;
  z-index: 2;
}

@media (max-width: 767px) {
  .index-banner .swiper-control {
    display: none;
  }
}

.index-banner .swiper-control .container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.index-banner .swiper-control .container .swiper-pagination {
  position: static;
  width: auto;
  flex-shrink: 0;
  margin-right: 10px;
  font-size: 20px;
}

@media (max-width: 1600px) {
  .index-banner .swiper-control .container .swiper-pagination {
    font-size: 18px;
  }
}

@media (max-width: 1359px) {
  .index-banner .swiper-control .container .swiper-pagination {
    font-size: 16px;
  }
}

.index-banner .swiper-control .container .swiper-pagination .swiper-pagination-current {
  margin-right: 5px;
}

.index-banner .swiper-control .container .swiper-pagination .swiper-pagination-total {
  opacity: .5;
  margin-left: 5px;
}

.index-banner .swiper-control .container .line {
  height: 1px;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.3);
}

.index-banner .swiper-control .container .swiper-btn {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-shrink: 0;
  margin-left: 10px;
  height: 40px;
}

@media (max-width: 1600px) {
  .index-banner .swiper-control .container .swiper-btn {
    height: 38px;
  }
}

@media (max-width: 1359px) {
  .index-banner .swiper-control .container .swiper-btn {
    height: 36px;
  }
}

@media (max-width: 1024px) {
  .index-banner .swiper-control .container .swiper-btn {
    height: 34px;
  }
}

@media (max-width: 991px) {
  .index-banner .swiper-control .container .swiper-btn {
    height: 32px;
  }
}

@media (max-width: 767px) {
  .index-banner .swiper-control .container .swiper-btn {
    height: 30px;
  }
}

.index-banner .swiper-control .container .swiper-btn .swiper-button-next, .index-banner .swiper-control .container .swiper-btn .swiper-button-prev {
  position: static;
  border: 1px solid #fff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  margin-top: 0;
  cursor: pointer;
  width: 40px;
  height: 40px;
}

@media (max-width: 1600px) {
  .index-banner .swiper-control .container .swiper-btn .swiper-button-next, .index-banner .swiper-control .container .swiper-btn .swiper-button-prev {
    width: 38px;
  }
}

@media (max-width: 1359px) {
  .index-banner .swiper-control .container .swiper-btn .swiper-button-next, .index-banner .swiper-control .container .swiper-btn .swiper-button-prev {
    width: 36px;
  }
}

@media (max-width: 1024px) {
  .index-banner .swiper-control .container .swiper-btn .swiper-button-next, .index-banner .swiper-control .container .swiper-btn .swiper-button-prev {
    width: 34px;
  }
}

@media (max-width: 991px) {
  .index-banner .swiper-control .container .swiper-btn .swiper-button-next, .index-banner .swiper-control .container .swiper-btn .swiper-button-prev {
    width: 32px;
  }
}

@media (max-width: 767px) {
  .index-banner .swiper-control .container .swiper-btn .swiper-button-next, .index-banner .swiper-control .container .swiper-btn .swiper-button-prev {
    width: 30px;
  }
}

@media (max-width: 1600px) {
  .index-banner .swiper-control .container .swiper-btn .swiper-button-next, .index-banner .swiper-control .container .swiper-btn .swiper-button-prev {
    height: 38px;
  }
}

@media (max-width: 1359px) {
  .index-banner .swiper-control .container .swiper-btn .swiper-button-next, .index-banner .swiper-control .container .swiper-btn .swiper-button-prev {
    height: 36px;
  }
}

@media (max-width: 1024px) {
  .index-banner .swiper-control .container .swiper-btn .swiper-button-next, .index-banner .swiper-control .container .swiper-btn .swiper-button-prev {
    height: 34px;
  }
}

@media (max-width: 991px) {
  .index-banner .swiper-control .container .swiper-btn .swiper-button-next, .index-banner .swiper-control .container .swiper-btn .swiper-button-prev {
    height: 32px;
  }
}

@media (max-width: 767px) {
  .index-banner .swiper-control .container .swiper-btn .swiper-button-next, .index-banner .swiper-control .container .swiper-btn .swiper-button-prev {
    height: 30px;
  }
}

.index-banner .swiper-control .container .swiper-btn .swiper-button-next::after, .index-banner .swiper-control .container .swiper-btn .swiper-button-prev::after {
  display: none;
}

.index-banner .swiper-control .container .swiper-btn .swiper-button-next {
  margin-left: 15px;
}

@media (max-width: 1600px) {
  .index-banner .swiper-control .container .swiper-btn .swiper-button-next {
    margin-left: 14px;
  }
}

@media (max-width: 1359px) {
  .index-banner .swiper-control .container .swiper-btn .swiper-button-next {
    margin-left: 13px;
  }
}

@media (max-width: 1024px) {
  .index-banner .swiper-control .container .swiper-btn .swiper-button-next {
    margin-left: 12px;
  }
}

@media (max-width: 991px) {
  .index-banner .swiper-control .container .swiper-btn .swiper-button-next {
    margin-left: 11px;
  }
}

@media (max-width: 767px) {
  .index-banner .swiper-control .container .swiper-btn .swiper-button-next {
    margin-left: 10px;
  }
}

.right-page {
  position: fixed;
  top: 50%;
  color: #fff;
  transform: translateY(-50%);
  z-index: 5;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  right: 2%;
  display: flex;
}

@media (max-width: 991px) {
  .right-page {
    right: 0.5%;
  }
}

@media (max-width: 767px) {
  .right-page {
    display: none;
  }
}

.right-page .page-prev, .right-page .page-next {
  cursor: pointer;
  transition: all 0.2s;
  opacity: .5;
}

.right-page .page-prev:hover, .right-page .page-next:hover {
  opacity: 1;
}

.right-page .page-title {
  overflow: hidden;
  writing-mode: tb-rl;
  text-transform: uppercase;
  font-size: 12px;
  margin-top: 20px;
  margin-bottom: 20px;
  width: 22px;
  overflow: hidden;
}

@media (max-width: 1600px) {
  .right-page .page-title {
    margin-top: 18px;
  }
}

@media (max-width: 1359px) {
  .right-page .page-title {
    margin-top: 16px;
  }
}

@media (max-width: 1024px) {
  .right-page .page-title {
    margin-top: 14px;
  }
}

@media (max-width: 991px) {
  .right-page .page-title {
    margin-top: 12px;
  }
}

@media (max-width: 767px) {
  .right-page .page-title {
    margin-top: 10px;
  }
}

@media (max-width: 1600px) {
  .right-page .page-title {
    margin-bottom: 18px;
  }
}

@media (max-width: 1359px) {
  .right-page .page-title {
    margin-bottom: 16px;
  }
}

@media (max-width: 1024px) {
  .right-page .page-title {
    margin-bottom: 14px;
  }
}

@media (max-width: 991px) {
  .right-page .page-title {
    margin-bottom: 12px;
  }
}

@media (max-width: 767px) {
  .right-page .page-title {
    margin-bottom: 10px;
  }
}

.right-page .page-title .cont {
  transition: all 0.2s;
}

.right-page .page-title .cont P {
  transform: rotate(180deg);
  width: 22px;
  text-align: center;
}

.page-swiper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  box-sizing: border-box;
  z-index: 1;
  padding-top: 110px;
  padding-left: 160px;
  padding-right: 160px;
  padding-bottom: 100px;
}

@media (max-width: 1600px) {
  .page-swiper {
    padding-top: 100px;
  }
}

@media (max-width: 1359px) {
  .page-swiper {
    padding-top: 90px;
  }
}

@media (max-width: 1024px) {
  .page-swiper {
    padding-top: 80px;
  }
}

@media (max-width: 991px) {
  .page-swiper {
    padding-top: 70px;
  }
}

@media (max-width: 767px) {
  .page-swiper {
    padding-top: 60px;
  }
}

@media (max-width: 1600px) {
  .page-swiper {
    padding-left: 128px;
  }
}

@media (max-width: 1359px) {
  .page-swiper {
    padding-left: 96px;
  }
}

@media (max-width: 1024px) {
  .page-swiper {
    padding-left: 64px;
  }
}

@media (max-width: 991px) {
  .page-swiper {
    padding-left: 32px;
  }
}

@media (max-width: 767px) {
  .page-swiper {
    padding-left: 0px;
  }
}

@media (max-width: 1600px) {
  .page-swiper {
    padding-right: 128px;
  }
}

@media (max-width: 1359px) {
  .page-swiper {
    padding-right: 96px;
  }
}

@media (max-width: 1024px) {
  .page-swiper {
    padding-right: 64px;
  }
}

@media (max-width: 991px) {
  .page-swiper {
    padding-right: 32px;
  }
}

@media (max-width: 767px) {
  .page-swiper {
    padding-right: 0px;
  }
}

@media (max-width: 1600px) {
  .page-swiper {
    padding-bottom: 90px;
  }
}

@media (max-width: 1359px) {
  .page-swiper {
    padding-bottom: 80px;
  }
}

@media (max-width: 1024px) {
  .page-swiper {
    padding-bottom: 70px;
  }
}

@media (max-width: 991px) {
  .page-swiper {
    padding-bottom: 60px;
  }
}

@media (max-width: 767px) {
  .page-swiper {
    padding-bottom: 50px;
  }
}

@media screen and (max-width: 1024px) {
  .page-swiper {
    padding-top: 31vh;
    padding-bottom: 31vh;
  }
}

@media screen and (max-width: 992px) {
  .page-swiper {
    padding-top: 20vh;
    padding-bottom: 20vh;
  }
}

@media screen and (max-width: 767px) {
  .page-swiper {
    padding-top: 110px;
    padding-bottom: 100px;
  }
}

@media screen and (max-width: 767px) and (max-width: 1600px) {
  .page-swiper {
    padding-top: 100px;
  }
}

@media screen and (max-width: 767px) and (max-width: 1359px) {
  .page-swiper {
    padding-top: 90px;
  }
}

@media screen and (max-width: 767px) and (max-width: 1024px) {
  .page-swiper {
    padding-top: 80px;
  }
}

@media screen and (max-width: 767px) and (max-width: 991px) {
  .page-swiper {
    padding-top: 70px;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .page-swiper {
    padding-top: 60px;
  }
}

@media screen and (max-width: 767px) and (max-width: 1600px) {
  .page-swiper {
    padding-bottom: 90px;
  }
}

@media screen and (max-width: 767px) and (max-width: 1359px) {
  .page-swiper {
    padding-bottom: 80px;
  }
}

@media screen and (max-width: 767px) and (max-width: 1024px) {
  .page-swiper {
    padding-bottom: 70px;
  }
}

@media screen and (max-width: 767px) and (max-width: 991px) {
  .page-swiper {
    padding-bottom: 60px;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .page-swiper {
    padding-bottom: 50px;
  }
}

.page-swiper.show-all .swiper-container {
  border-radius: 0;
}

.page-swiper .swiper-pagination {
  position: fixed;
  top: 50%;
  z-index: 5;
  transform: translateY(-50%);
  flex-direction: column;
  left: 2%;
  display: flex;
}

@media (max-width: 991px) {
  .page-swiper .swiper-pagination {
    left: 1%;
  }
}

@media (max-width: 767px) {
  .page-swiper .swiper-pagination {
    display: none;
  }
}

.page-swiper .swiper-pagination .swiper-pagination-bullet {
  width: 20px;
  padding: 8px 0;
  height: auto;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
}

.page-swiper .swiper-pagination .swiper-pagination-bullet::after {
  content: '';
  display: block;
  width: 5px;
  height: 5px;
  border-radius: 5px;
  background-color: #fff;
  opacity: .5;
  transition: all 0.2s;
}

.page-swiper .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active::after {
  height: 30px;
  opacity: 1;
}

.page-swiper .swiper-copyright {
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  z-index: 4;
  height: 100px;
  padding-left: 190px;
  padding-right: 190px;
}

@media (max-width: 1600px) {
  .page-swiper .swiper-copyright {
    height: 90px;
  }
}

@media (max-width: 1359px) {
  .page-swiper .swiper-copyright {
    height: 80px;
  }
}

@media (max-width: 1024px) {
  .page-swiper .swiper-copyright {
    height: 70px;
  }
}

@media (max-width: 991px) {
  .page-swiper .swiper-copyright {
    height: 60px;
  }
}

@media (max-width: 767px) {
  .page-swiper .swiper-copyright {
    height: 50px;
  }
}

@media (max-width: 1600px) {
  .page-swiper .swiper-copyright {
    padding-left: 154px;
  }
}

@media (max-width: 1359px) {
  .page-swiper .swiper-copyright {
    padding-left: 118px;
  }
}

@media (max-width: 1024px) {
  .page-swiper .swiper-copyright {
    padding-left: 82px;
  }
}

@media (max-width: 991px) {
  .page-swiper .swiper-copyright {
    padding-left: 46px;
  }
}

@media (max-width: 767px) {
  .page-swiper .swiper-copyright {
    padding-left: 10px;
  }
}

@media (max-width: 1600px) {
  .page-swiper .swiper-copyright {
    padding-right: 154px;
  }
}

@media (max-width: 1359px) {
  .page-swiper .swiper-copyright {
    padding-right: 118px;
  }
}

@media (max-width: 1024px) {
  .page-swiper .swiper-copyright {
    padding-right: 82px;
  }
}

@media (max-width: 991px) {
  .page-swiper .swiper-copyright {
    padding-right: 46px;
  }
}

@media (max-width: 767px) {
  .page-swiper .swiper-copyright {
    padding-right: 10px;
  }
}

@media screen and (max-width: 1024px) {
  .page-swiper .swiper-copyright {
    bottom: calc(30vh - 70px);
  }
}

@media screen and (max-width: 992px) {
  .page-swiper .swiper-copyright {
    bottom: calc(20vh - 60px);
  }
}

@media screen and (max-width: 767px) {
  .page-swiper .swiper-copyright {
    bottom: 0;
  }
}

.page-swiper .swiper-copyright .cont {
  height: 100%;
  color: #fff;
  align-items: center;
  display: flex;
  justify-content: space-between;
  line-height: 1.5;
  flex-direction: row;
  padding-top: 20px;
  padding-bottom: 20px;
}

@media (max-width: 767px) {
  .page-swiper .swiper-copyright .cont {
    font-size: 0.24rem;
  }
}

@media (max-width: 767px) {
  .page-swiper .swiper-copyright .cont {
    justify-content: center;
  }
}

@media (max-width: 1600px) {
  .page-swiper .swiper-copyright .cont {
    line-height: 1.4;
  }
}

@media (max-width: 1359px) {
  .page-swiper .swiper-copyright .cont {
    line-height: 1.3;
  }
}

@media (max-width: 1024px) {
  .page-swiper .swiper-copyright .cont {
    line-height: 1.2;
  }
}

@media (max-width: 991px) {
  .page-swiper .swiper-copyright .cont {
    line-height: 1.1;
  }
}

@media (max-width: 767px) {
  .page-swiper .swiper-copyright .cont {
    line-height: 1;
  }
}

@media (max-width: 767px) {
  .page-swiper .swiper-copyright .cont {
    text-align: center;
  }
}

@media (max-width: 767px) {
  .page-swiper .swiper-copyright .cont {
    flex-direction: column;
  }
}

@media (max-width: 1600px) {
  .page-swiper .swiper-copyright .cont {
    padding-top: 16px;
  }
}

@media (max-width: 1359px) {
  .page-swiper .swiper-copyright .cont {
    padding-top: 12px;
  }
}

@media (max-width: 1024px) {
  .page-swiper .swiper-copyright .cont {
    padding-top: 8px;
  }
}

@media (max-width: 991px) {
  .page-swiper .swiper-copyright .cont {
    padding-top: 4px;
  }
}

@media (max-width: 767px) {
  .page-swiper .swiper-copyright .cont {
    padding-top: 0px;
  }
}

@media (max-width: 1600px) {
  .page-swiper .swiper-copyright .cont {
    padding-bottom: 16px;
  }
}

@media (max-width: 1359px) {
  .page-swiper .swiper-copyright .cont {
    padding-bottom: 12px;
  }
}

@media (max-width: 1024px) {
  .page-swiper .swiper-copyright .cont {
    padding-bottom: 8px;
  }
}

@media (max-width: 991px) {
  .page-swiper .swiper-copyright .cont {
    padding-bottom: 4px;
  }
}

@media (max-width: 767px) {
  .page-swiper .swiper-copyright .cont {
    padding-bottom: 0px;
  }
}

@media (max-width: 991px) {
  .page-swiper .swiper-copyright .cont {
    margin-left: -20px;
  }
}

@media (max-width: 767px) {
  .page-swiper .swiper-copyright .cont {
    margin-left: 0;
  }
}

@media (max-width: 991px) {
  .page-swiper .swiper-copyright .cont {
    margin-right: -20px;
  }
}

@media (max-width: 767px) {
  .page-swiper .swiper-copyright .cont {
    margin-right: 0;
  }
}

@media (max-width: 767px) {
  .page-swiper .swiper-copyright .cont .beian {
    margin-bottom: 0.1rem;
  }
}

.page-swiper .swiper-copyright .cont span {
  margin-left: 10px;
  margin-right: 10px;
}

@media (max-width: 1024px) {
  .page-swiper .swiper-copyright .cont .beian + div a:first-child, .page-swiper .swiper-copyright .cont .beian + div a:first-child + span {
    display: none;
  }
}

.page-swiper .swiper-bottom {
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  z-index: 5;
  transition: all 0.2s;
  height: 100px;
  padding-left: 160px;
  padding-right: 160px;
}

@media (max-width: 1600px) {
  .page-swiper .swiper-bottom {
    height: 90px;
  }
}

@media (max-width: 1359px) {
  .page-swiper .swiper-bottom {
    height: 80px;
  }
}

@media (max-width: 1024px) {
  .page-swiper .swiper-bottom {
    height: 70px;
  }
}

@media (max-width: 991px) {
  .page-swiper .swiper-bottom {
    height: 60px;
  }
}

@media (max-width: 767px) {
  .page-swiper .swiper-bottom {
    height: 50px;
  }
}

@media (max-width: 1600px) {
  .page-swiper .swiper-bottom {
    padding-left: 128px;
  }
}

@media (max-width: 1359px) {
  .page-swiper .swiper-bottom {
    padding-left: 96px;
  }
}

@media (max-width: 1024px) {
  .page-swiper .swiper-bottom {
    padding-left: 64px;
  }
}

@media (max-width: 991px) {
  .page-swiper .swiper-bottom {
    padding-left: 32px;
  }
}

@media (max-width: 767px) {
  .page-swiper .swiper-bottom {
    padding-left: 0px;
  }
}

@media (max-width: 1600px) {
  .page-swiper .swiper-bottom {
    padding-right: 128px;
  }
}

@media (max-width: 1359px) {
  .page-swiper .swiper-bottom {
    padding-right: 96px;
  }
}

@media (max-width: 1024px) {
  .page-swiper .swiper-bottom {
    padding-right: 64px;
  }
}

@media (max-width: 991px) {
  .page-swiper .swiper-bottom {
    padding-right: 32px;
  }
}

@media (max-width: 767px) {
  .page-swiper .swiper-bottom {
    padding-right: 0px;
  }
}

@media screen and (max-width: 1024px) {
  .page-swiper .swiper-bottom {
    bottom: calc(30vh - 70px);
  }
}

@media screen and (max-width: 992px) {
  .page-swiper .swiper-bottom {
    bottom: calc(20vh - 60px);
  }
}

@media screen and (max-width: 767px) {
  .page-swiper .swiper-bottom {
    bottom: 0;
  }
}

.page-swiper .swiper-bottom .cont {
  height: 100%;
  background: #252d67;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  color: #fff;
  transition: all 0.2s;
  padding-left: 75px;
  padding-right: 75px;
}

@media (max-width: 1600px) {
  .page-swiper .swiper-bottom .cont {
    padding-left: 62px;
  }
}

@media (max-width: 1359px) {
  .page-swiper .swiper-bottom .cont {
    padding-left: 49px;
  }
}

@media (max-width: 1024px) {
  .page-swiper .swiper-bottom .cont {
    padding-left: 36px;
  }
}

@media (max-width: 991px) {
  .page-swiper .swiper-bottom .cont {
    padding-left: 23px;
  }
}

@media (max-width: 767px) {
  .page-swiper .swiper-bottom .cont {
    padding-left: 10px;
  }
}

@media (max-width: 1600px) {
  .page-swiper .swiper-bottom .cont {
    padding-right: 62px;
  }
}

@media (max-width: 1359px) {
  .page-swiper .swiper-bottom .cont {
    padding-right: 49px;
  }
}

@media (max-width: 1024px) {
  .page-swiper .swiper-bottom .cont {
    padding-right: 36px;
  }
}

@media (max-width: 991px) {
  .page-swiper .swiper-bottom .cont {
    padding-right: 23px;
  }
}

@media (max-width: 767px) {
  .page-swiper .swiper-bottom .cont {
    padding-right: 10px;
  }
}

.page-swiper .swiper-bottom .cont .left {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.page-swiper .swiper-bottom .cont .left .yuan {
  border-radius: 50%;
  border-style: solid;
  border-color: #5fb4ef;
  transition: all 0.2s;
  width: 12px;
  height: 12px;
  border-width: 3px;
}

@media (max-width: 1600px) {
  .page-swiper .swiper-bottom .cont .left .yuan {
    width: 11.6px;
  }
}

@media (max-width: 1359px) {
  .page-swiper .swiper-bottom .cont .left .yuan {
    width: 11.2px;
  }
}

@media (max-width: 1024px) {
  .page-swiper .swiper-bottom .cont .left .yuan {
    width: 10.8px;
  }
}

@media (max-width: 991px) {
  .page-swiper .swiper-bottom .cont .left .yuan {
    width: 10.4px;
  }
}

@media (max-width: 767px) {
  .page-swiper .swiper-bottom .cont .left .yuan {
    width: 10px;
  }
}

@media (max-width: 1600px) {
  .page-swiper .swiper-bottom .cont .left .yuan {
    height: 11.6px;
  }
}

@media (max-width: 1359px) {
  .page-swiper .swiper-bottom .cont .left .yuan {
    height: 11.2px;
  }
}

@media (max-width: 1024px) {
  .page-swiper .swiper-bottom .cont .left .yuan {
    height: 10.8px;
  }
}

@media (max-width: 991px) {
  .page-swiper .swiper-bottom .cont .left .yuan {
    height: 10.4px;
  }
}

@media (max-width: 767px) {
  .page-swiper .swiper-bottom .cont .left .yuan {
    height: 10px;
  }
}

@media (max-width: 1600px) {
  .page-swiper .swiper-bottom .cont .left .yuan {
    border-width: 2.8px;
  }
}

@media (max-width: 1359px) {
  .page-swiper .swiper-bottom .cont .left .yuan {
    border-width: 2.6px;
  }
}

@media (max-width: 1024px) {
  .page-swiper .swiper-bottom .cont .left .yuan {
    border-width: 2.4px;
  }
}

@media (max-width: 991px) {
  .page-swiper .swiper-bottom .cont .left .yuan {
    border-width: 2.2px;
  }
}

@media (max-width: 767px) {
  .page-swiper .swiper-bottom .cont .left .yuan {
    border-width: 2px;
  }
}

.page-swiper .swiper-bottom .cont .left .text {
  line-height: 28px;
  height: 28px;
  overflow: hidden;
  margin-left: 10px;
}

@media (max-width: 1600px) {
  .page-swiper .swiper-bottom .cont .left .text {
    margin-left: 9px;
  }
}

@media (max-width: 1359px) {
  .page-swiper .swiper-bottom .cont .left .text {
    margin-left: 8px;
  }
}

@media (max-width: 1024px) {
  .page-swiper .swiper-bottom .cont .left .text {
    margin-left: 7px;
  }
}

@media (max-width: 991px) {
  .page-swiper .swiper-bottom .cont .left .text {
    margin-left: 6px;
  }
}

@media (max-width: 767px) {
  .page-swiper .swiper-bottom .cont .left .text {
    margin-left: 5px;
  }
}

.page-swiper .swiper-bottom .cont .left .text .text-cont {
  font-family: 'Lato-Bold';
  transition: all 0.2s;
  font-size: 20px;
}

@media (max-width: 1600px) {
  .page-swiper .swiper-bottom .cont .left .text .text-cont {
    font-size: 18px;
  }
}

@media (max-width: 1359px) {
  .page-swiper .swiper-bottom .cont .left .text .text-cont {
    font-size: 16px;
  }
}

.page-swiper .swiper-bottom .cont .left .text .text-cont p {
  height: 28px;
  text-transform: capitalize;
}

.page-swiper .swiper-bottom .cont .left .text .text-cont p:nth-child(1) {
  color: #fff;
}

.page-swiper .swiper-bottom .cont .left .text .text-cont p:nth-child(2) {
  color: #333333;
}

.page-swiper .swiper-bottom .cont .left .text .text-cont p:nth-child(3) {
  color: #fff;
}

.page-swiper .swiper-bottom .cont .left .text .text-cont p:nth-child(4) {
  color: #333333;
}

.page-swiper .swiper-bottom .cont .left .text .text-cont p:nth-child(5) {
  color: #fff;
}

.page-swiper .swiper-bottom .cont .center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  border-radius: 50%;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s;
  width: 42px;
  height: 42px;
}

@media (max-width: 1600px) {
  .page-swiper .swiper-bottom .cont .center {
    width: 40.6px;
  }
}

@media (max-width: 1359px) {
  .page-swiper .swiper-bottom .cont .center {
    width: 39.2px;
  }
}

@media (max-width: 1024px) {
  .page-swiper .swiper-bottom .cont .center {
    width: 37.8px;
  }
}

@media (max-width: 991px) {
  .page-swiper .swiper-bottom .cont .center {
    width: 36.4px;
  }
}

@media (max-width: 767px) {
  .page-swiper .swiper-bottom .cont .center {
    width: 35px;
  }
}

@media (max-width: 1600px) {
  .page-swiper .swiper-bottom .cont .center {
    height: 40.6px;
  }
}

@media (max-width: 1359px) {
  .page-swiper .swiper-bottom .cont .center {
    height: 39.2px;
  }
}

@media (max-width: 1024px) {
  .page-swiper .swiper-bottom .cont .center {
    height: 37.8px;
  }
}

@media (max-width: 991px) {
  .page-swiper .swiper-bottom .cont .center {
    height: 36.4px;
  }
}

@media (max-width: 767px) {
  .page-swiper .swiper-bottom .cont .center {
    height: 35px;
  }
}

.page-swiper .swiper-bottom .cont .center .outer-line {
  width: 12px;
  height: 19px;
  border-radius: 10px;
  border: 1px solid #5e5e5e;
  position: relative;
  overflow: hidden;
}

.page-swiper .swiper-bottom .cont .center .outer-line .inner-line {
  width: 3px;
  height: 6px;
  border-radius: 3px;
  border: 1px solid #5e5e5e;
  position: absolute;
  top: 3px;
  left: 50%;
  transform: translateX(-50%);
  animation: rain 1s infinite linear;
}

.page-swiper .swiper-bottom .cont .right {
  opacity: .6;
  font-size: 16px;
}

@media (max-width: 1600px) {
  .page-swiper .swiper-bottom .cont .right {
    font-size: 14px;
  }
}

.page-swiper .swiper-container {
  height: 100%;
  transition: all 0.2s;
  border-top-left-radius: 80px;
}

@media (max-width: 1600px) {
  .page-swiper .swiper-container {
    border-top-left-radius: 70px;
  }
}

@media (max-width: 1359px) {
  .page-swiper .swiper-container {
    border-top-left-radius: 60px;
  }
}

@media (max-width: 1024px) {
  .page-swiper .swiper-container {
    border-top-left-radius: 50px;
  }
}

@media (max-width: 991px) {
  .page-swiper .swiper-container {
    border-top-left-radius: 40px;
  }
}

@media (max-width: 767px) {
  .page-swiper .swiper-container {
    border-top-left-radius: 30px;
  }
}

.page-swiper .swiper-container .swiper-aside {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 4;
  box-sizing: border-box;
  opacity: 0;
  transition: all 0.2s;
  align-items: center;
  justify-content: flex-start;
  padding-left: 75px;
  padding-top: 90px;
  width: 24%;
  height: 100%;
}

@media (max-width: 1600px) {
  .page-swiper .swiper-container .swiper-aside {
    padding-left: 64px;
  }
}

@media (max-width: 1359px) {
  .page-swiper .swiper-container .swiper-aside {
    padding-left: 53px;
  }
}

@media (max-width: 1024px) {
  .page-swiper .swiper-container .swiper-aside {
    padding-left: 42px;
  }
}

@media (max-width: 991px) {
  .page-swiper .swiper-container .swiper-aside {
    padding-left: 31px;
  }
}

@media (max-width: 767px) {
  .page-swiper .swiper-container .swiper-aside {
    padding-left: 20px;
  }
}

@media (max-width: 1600px) {
  .page-swiper .swiper-container .swiper-aside {
    padding-top: 76px;
  }
}

@media (max-width: 1359px) {
  .page-swiper .swiper-container .swiper-aside {
    padding-top: 62px;
  }
}

@media (max-width: 1024px) {
  .page-swiper .swiper-container .swiper-aside {
    padding-top: 48px;
  }
}

@media (max-width: 991px) {
  .page-swiper .swiper-container .swiper-aside {
    padding-top: 34px;
  }
}

@media (max-width: 767px) {
  .page-swiper .swiper-container .swiper-aside {
    padding-top: 20px;
  }
}

@media (max-width: 991px) {
  .page-swiper .swiper-container .swiper-aside {
    width: 100%;
  }
}

@media (max-width: 991px) {
  .page-swiper .swiper-container .swiper-aside {
    height: 50px;
  }
}

@media screen and (max-width: 991px) {
  .page-swiper .swiper-container .swiper-aside {
    height: 76px;
  }
}

@media screen and (max-width: 771px) {
  .page-swiper .swiper-container .swiper-aside {
    height: 100%;
  }
}

@media screen and (max-width: 771px) and (max-width: 991px) {
  .page-swiper .swiper-container .swiper-aside {
    height: 50px;
  }
}

@media (max-width: 991px) {
  .page-swiper .swiper-container .swiper-aside {
    display: flex;
  }
}

.page-swiper .swiper-container .swiper-aside .title {
  position: relative;
  font-family: 'Lato-Bold';
  font-size: 34px;
}

@media (max-width: 1600px) {
  .page-swiper .swiper-container .swiper-aside .title {
    font-size: 30px;
  }
}

@media (max-width: 1359px) {
  .page-swiper .swiper-container .swiper-aside .title {
    font-size: 26px;
  }
}

@media (max-width: 1024px) {
  .page-swiper .swiper-container .swiper-aside .title {
    font-size: 18px;
  }
}

.page-swiper .swiper-container .swiper-aside .title .dian {
  content: '';
  display: block;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: #5fb4ef;
  transition: all 0.2s;
  left: -22px;
  width: 8px;
  height: 8px;
}

@media (max-width: 1600px) {
  .page-swiper .swiper-container .swiper-aside .title .dian {
    left: -19.6px;
  }
}

@media (max-width: 1359px) {
  .page-swiper .swiper-container .swiper-aside .title .dian {
    left: -17.2px;
  }
}

@media (max-width: 1024px) {
  .page-swiper .swiper-container .swiper-aside .title .dian {
    left: -14.8px;
  }
}

@media (max-width: 991px) {
  .page-swiper .swiper-container .swiper-aside .title .dian {
    left: -12.4px;
  }
}

@media (max-width: 767px) {
  .page-swiper .swiper-container .swiper-aside .title .dian {
    left: -10px;
  }
}

@media (max-width: 1600px) {
  .page-swiper .swiper-container .swiper-aside .title .dian {
    width: 7.4px;
  }
}

@media (max-width: 1359px) {
  .page-swiper .swiper-container .swiper-aside .title .dian {
    width: 6.8px;
  }
}

@media (max-width: 1024px) {
  .page-swiper .swiper-container .swiper-aside .title .dian {
    width: 6.2px;
  }
}

@media (max-width: 991px) {
  .page-swiper .swiper-container .swiper-aside .title .dian {
    width: 5.6px;
  }
}

@media (max-width: 767px) {
  .page-swiper .swiper-container .swiper-aside .title .dian {
    width: 5px;
  }
}

@media (max-width: 1600px) {
  .page-swiper .swiper-container .swiper-aside .title .dian {
    height: 7.4px;
  }
}

@media (max-width: 1359px) {
  .page-swiper .swiper-container .swiper-aside .title .dian {
    height: 6.8px;
  }
}

@media (max-width: 1024px) {
  .page-swiper .swiper-container .swiper-aside .title .dian {
    height: 6.2px;
  }
}

@media (max-width: 991px) {
  .page-swiper .swiper-container .swiper-aside .title .dian {
    height: 5.6px;
  }
}

@media (max-width: 767px) {
  .page-swiper .swiper-container .swiper-aside .title .dian {
    height: 5px;
  }
}

.page-swiper .swiper-container .swiper-aside .title .title-show-cont {
  overflow: hidden;
  height: 40px;
  line-height: 40px;
}

@media (max-width: 1600px) {
  .page-swiper .swiper-container .swiper-aside .title .title-show-cont {
    height: 36.8px;
  }
}

@media (max-width: 1359px) {
  .page-swiper .swiper-container .swiper-aside .title .title-show-cont {
    height: 33.6px;
  }
}

@media (max-width: 1024px) {
  .page-swiper .swiper-container .swiper-aside .title .title-show-cont {
    height: 30.4px;
  }
}

@media (max-width: 991px) {
  .page-swiper .swiper-container .swiper-aside .title .title-show-cont {
    height: 27.2px;
  }
}

@media (max-width: 767px) {
  .page-swiper .swiper-container .swiper-aside .title .title-show-cont {
    height: 24px;
  }
}

@media (max-width: 1600px) {
  .page-swiper .swiper-container .swiper-aside .title .title-show-cont {
    line-height: 36.8px;
  }
}

@media (max-width: 1359px) {
  .page-swiper .swiper-container .swiper-aside .title .title-show-cont {
    line-height: 33.6px;
  }
}

@media (max-width: 1024px) {
  .page-swiper .swiper-container .swiper-aside .title .title-show-cont {
    line-height: 30.4px;
  }
}

@media (max-width: 991px) {
  .page-swiper .swiper-container .swiper-aside .title .title-show-cont {
    line-height: 27.2px;
  }
}

@media (max-width: 767px) {
  .page-swiper .swiper-container .swiper-aside .title .title-show-cont {
    line-height: 24px;
  }
}

.page-swiper .swiper-container .swiper-aside .title .title-show-cont .title-cont {
  transition: all 0.2s;
}

.page-swiper .swiper-container .swiper-aside .title .title-show-cont .title-cont p {
  text-transform: uppercase;
  height: 40px;
}

@media (max-width: 1600px) {
  .page-swiper .swiper-container .swiper-aside .title .title-show-cont .title-cont p {
    height: 36.8px;
  }
}

@media (max-width: 1359px) {
  .page-swiper .swiper-container .swiper-aside .title .title-show-cont .title-cont p {
    height: 33.6px;
  }
}

@media (max-width: 1024px) {
  .page-swiper .swiper-container .swiper-aside .title .title-show-cont .title-cont p {
    height: 30.4px;
  }
}

@media (max-width: 991px) {
  .page-swiper .swiper-container .swiper-aside .title .title-show-cont .title-cont p {
    height: 27.2px;
  }
}

@media (max-width: 767px) {
  .page-swiper .swiper-container .swiper-aside .title .title-show-cont .title-cont p {
    height: 24px;
  }
}

.page-swiper .swiper-container .swiper-aside .title .title-show-cont .title-cont p:nth-child(2) {
  color: #5fb4ef;
}

.page-swiper .swiper-container .swiper-aside .title .title-show-cont .title-cont p:nth-child(3) {
  color: #333333;
}

.page-swiper .swiper-container .swiper-aside .title .title-show-cont .title-cont p:nth-child(4) {
  color: #252d67;
}

.page-swiper .swiper-container .swiper-aside .title .title-show-cont .title-cont p:nth-child(5) {
  color: #333333;
}

.page-swiper .swiper-container .swiper-aside .number {
  overflow: hidden;
  position: relative;
  font-family: 'DIN';
  line-height: 1;
  margin-left: 0;
  height: 80px;
  line-height: 80px;
  font-size: 80px;
}

@media (max-width: 991px) {
  .page-swiper .swiper-container .swiper-aside .number {
    margin-left: 20px;
  }
}

@media (max-width: 767px) {
  .page-swiper .swiper-container .swiper-aside .number {
    margin-left: 10px;
  }
}

@media (max-width: 1600px) {
  .page-swiper .swiper-container .swiper-aside .number {
    height: 68.8px;
  }
}

@media (max-width: 1359px) {
  .page-swiper .swiper-container .swiper-aside .number {
    height: 57.6px;
  }
}

@media (max-width: 1024px) {
  .page-swiper .swiper-container .swiper-aside .number {
    height: 46.4px;
  }
}

@media (max-width: 991px) {
  .page-swiper .swiper-container .swiper-aside .number {
    height: 35.2px;
  }
}

@media (max-width: 767px) {
  .page-swiper .swiper-container .swiper-aside .number {
    height: 24px;
  }
}

@media (max-width: 1600px) {
  .page-swiper .swiper-container .swiper-aside .number {
    line-height: 68.8px;
  }
}

@media (max-width: 1359px) {
  .page-swiper .swiper-container .swiper-aside .number {
    line-height: 57.6px;
  }
}

@media (max-width: 1024px) {
  .page-swiper .swiper-container .swiper-aside .number {
    line-height: 46.4px;
  }
}

@media (max-width: 991px) {
  .page-swiper .swiper-container .swiper-aside .number {
    line-height: 35.2px;
  }
}

@media (max-width: 767px) {
  .page-swiper .swiper-container .swiper-aside .number {
    line-height: 24px;
  }
}

@media (max-width: 1600px) {
  .page-swiper .swiper-container .swiper-aside .number {
    font-size: 68px;
  }
}

@media (max-width: 1359px) {
  .page-swiper .swiper-container .swiper-aside .number {
    font-size: 56px;
  }
}

@media (max-width: 1024px) {
  .page-swiper .swiper-container .swiper-aside .number {
    font-size: 44px;
  }
}

@media (max-width: 991px) {
  .page-swiper .swiper-container .swiper-aside .number {
    font-size: 32px;
  }
}

@media (max-width: 767px) {
  .page-swiper .swiper-container .swiper-aside .number {
    font-size: 20px;
  }
}

.page-swiper .swiper-container .swiper-aside .number .number-cont {
  transition: all 0.2s;
}

.page-swiper .swiper-container .swiper-aside .number .number-cont p {
  height: 80px;
}

@media (max-width: 1600px) {
  .page-swiper .swiper-container .swiper-aside .number .number-cont p {
    height: 68.8px;
  }
}

@media (max-width: 1359px) {
  .page-swiper .swiper-container .swiper-aside .number .number-cont p {
    height: 57.6px;
  }
}

@media (max-width: 1024px) {
  .page-swiper .swiper-container .swiper-aside .number .number-cont p {
    height: 46.4px;
  }
}

@media (max-width: 991px) {
  .page-swiper .swiper-container .swiper-aside .number .number-cont p {
    height: 35.2px;
  }
}

@media (max-width: 767px) {
  .page-swiper .swiper-container .swiper-aside .number .number-cont p {
    height: 24px;
  }
}

.page-swiper .swiper-container .swiper-aside .number .number-cont p:nth-child(2) {
  color: #515785;
}

.page-swiper .swiper-container .swiper-aside .number .number-cont p:nth-child(3) {
  color: #cccccc;
}

.page-swiper .swiper-container .swiper-aside .number .number-cont p:nth-child(4) {
  color: #6bb2e4;
}

.page-swiper .swiper-container .swiper-aside .number .number-cont p:nth-child(5) {
  color: #cccccc;
}

.page-swiper .swiper-container .swiper-aside .more-link {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  bottom: 90px;
}

@media (max-width: 1600px) {
  .page-swiper .swiper-container .swiper-aside .more-link {
    bottom: 76px;
  }
}

@media (max-width: 1359px) {
  .page-swiper .swiper-container .swiper-aside .more-link {
    bottom: 62px;
  }
}

@media (max-width: 1024px) {
  .page-swiper .swiper-container .swiper-aside .more-link {
    bottom: 48px;
  }
}

@media (max-width: 991px) {
  .page-swiper .swiper-container .swiper-aside .more-link {
    bottom: 34px;
  }
}

@media (max-width: 767px) {
  .page-swiper .swiper-container .swiper-aside .more-link {
    bottom: 20px;
  }
}

.page-swiper .swiper-container .swiper-aside .more-link .text {
  writing-mode: tb-rl;
  transform: rotate(180deg);
  font-size: 14px;
  font-family: 'DIN';
  transition: all 0.2s;
}

@media (max-width: 991px) {
  .page-swiper .swiper-container .swiper-aside .more-link .text {
    display: none;
  }
}

.page-swiper .swiper-container .swiper-aside .more-link .icon {
  border-radius: 50%;
  background: #469fdd;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s;
  width: 57px;
  height: 57px;
  margin-top: 17px;
}

@media (max-width: 1600px) {
  .page-swiper .swiper-container .swiper-aside .more-link .icon {
    width: 52.6px;
  }
}

@media (max-width: 1359px) {
  .page-swiper .swiper-container .swiper-aside .more-link .icon {
    width: 48.2px;
  }
}

@media (max-width: 1024px) {
  .page-swiper .swiper-container .swiper-aside .more-link .icon {
    width: 43.8px;
  }
}

@media (max-width: 991px) {
  .page-swiper .swiper-container .swiper-aside .more-link .icon {
    width: 39.4px;
  }
}

@media (max-width: 767px) {
  .page-swiper .swiper-container .swiper-aside .more-link .icon {
    width: 35px;
  }
}

@media (max-width: 1600px) {
  .page-swiper .swiper-container .swiper-aside .more-link .icon {
    height: 52.6px;
  }
}

@media (max-width: 1359px) {
  .page-swiper .swiper-container .swiper-aside .more-link .icon {
    height: 48.2px;
  }
}

@media (max-width: 1024px) {
  .page-swiper .swiper-container .swiper-aside .more-link .icon {
    height: 43.8px;
  }
}

@media (max-width: 991px) {
  .page-swiper .swiper-container .swiper-aside .more-link .icon {
    height: 39.4px;
  }
}

@media (max-width: 767px) {
  .page-swiper .swiper-container .swiper-aside .more-link .icon {
    height: 35px;
  }
}

@media (max-width: 1600px) {
  .page-swiper .swiper-container .swiper-aside .more-link .icon {
    margin-top: 15.6px;
  }
}

@media (max-width: 1359px) {
  .page-swiper .swiper-container .swiper-aside .more-link .icon {
    margin-top: 14.2px;
  }
}

@media (max-width: 1024px) {
  .page-swiper .swiper-container .swiper-aside .more-link .icon {
    margin-top: 12.8px;
  }
}

@media (max-width: 991px) {
  .page-swiper .swiper-container .swiper-aside .more-link .icon {
    margin-top: 11.4px;
  }
}

@media (max-width: 767px) {
  .page-swiper .swiper-container .swiper-aside .more-link .icon {
    margin-top: 10px;
  }
}

.page-swiper .swiper-container .swiper-aside .more-link .icon:hover .iconfont {
  transform: rotate(90deg);
}

.page-swiper .swiper-container .swiper-aside .more-link .icon .iconfont {
  transition: all 0.2s;
  font-size: 26px;
}

@media (max-width: 1600px) {
  .page-swiper .swiper-container .swiper-aside .more-link .icon .iconfont {
    font-size: 24.8px;
  }
}

@media (max-width: 1359px) {
  .page-swiper .swiper-container .swiper-aside .more-link .icon .iconfont {
    font-size: 23.6px;
  }
}

@media (max-width: 1024px) {
  .page-swiper .swiper-container .swiper-aside .more-link .icon .iconfont {
    font-size: 22.4px;
  }
}

@media (max-width: 991px) {
  .page-swiper .swiper-container .swiper-aside .more-link .icon .iconfont {
    font-size: 21.2px;
  }
}

@media (max-width: 767px) {
  .page-swiper .swiper-container .swiper-aside .more-link .icon .iconfont {
    font-size: 20px;
  }
}

.page-swiper .swiper-container .swiper-wrapper {
  transition: all cubic-bezier(0.13, 0.63, 0.6, 0.98);
}

.page-swiper .swiper-container .swiper-slide {
  overflow: hidden;
  box-sizing: border-box;
  justify-content: center;
  flex-direction: column;
  padding-top: 90px;
  padding-right: 75px;
}

@media (max-width: 991px) {
  .page-swiper .swiper-container .swiper-slide {
    display: flex;
  }
}

@media (max-width: 1600px) {
  .page-swiper .swiper-container .swiper-slide {
    padding-top: 76px;
  }
}

@media (max-width: 1359px) {
  .page-swiper .swiper-container .swiper-slide {
    padding-top: 62px;
  }
}

@media (max-width: 1024px) {
  .page-swiper .swiper-container .swiper-slide {
    padding-top: 48px;
  }
}

@media (max-width: 991px) {
  .page-swiper .swiper-container .swiper-slide {
    padding-top: 34px;
  }
}

@media (max-width: 767px) {
  .page-swiper .swiper-container .swiper-slide {
    padding-top: 20px;
  }
}

@media (max-width: 1600px) {
  .page-swiper .swiper-container .swiper-slide {
    padding-right: 62px;
  }
}

@media (max-width: 1359px) {
  .page-swiper .swiper-container .swiper-slide {
    padding-right: 49px;
  }
}

@media (max-width: 1024px) {
  .page-swiper .swiper-container .swiper-slide {
    padding-right: 36px;
  }
}

@media (max-width: 991px) {
  .page-swiper .swiper-container .swiper-slide {
    padding-right: 23px;
  }
}

@media (max-width: 767px) {
  .page-swiper .swiper-container .swiper-slide {
    padding-right: 10px;
  }
}

@media screen and (max-width: 1600px) {
  .page-swiper .swiper-container .swiper-slide {
    padding-top: 50px;
  }
}

@media screen and (max-width: 1600px) and (max-width: 1600px) {
  .page-swiper .swiper-container .swiper-slide {
    padding-top: 44px;
  }
}

@media screen and (max-width: 1600px) and (max-width: 1359px) {
  .page-swiper .swiper-container .swiper-slide {
    padding-top: 38px;
  }
}

@media screen and (max-width: 1600px) and (max-width: 1024px) {
  .page-swiper .swiper-container .swiper-slide {
    padding-top: 32px;
  }
}

@media screen and (max-width: 1600px) and (max-width: 991px) {
  .page-swiper .swiper-container .swiper-slide {
    padding-top: 26px;
  }
}

@media screen and (max-width: 1600px) and (max-width: 767px) {
  .page-swiper .swiper-container .swiper-slide {
    padding-top: 20px;
  }
}

.page-swiper .swiper-container .swiper-slide:not(:last-child) {
  padding-left: 24%;
}

@media (max-width: 991px) {
  .page-swiper .swiper-container .swiper-slide:not(:last-child) {
    padding-left: 20px;
  }
}

@media (max-width: 767px) {
  .page-swiper .swiper-container .swiper-slide:not(:last-child) {
    padding-left: 10px;
  }
}

.page-swiper .swiper-container .swiper-slide:nth-child(1) {
  opacity: 0;
  visibility: hidden;
  background-color: transparent;
}

.page-swiper .swiper-container .swiper-slide:nth-child(2) {
  background-color: #252d67;
  padding-bottom: 0px;
}

@media (max-width: 991px) {
  .page-swiper .swiper-container .swiper-slide:nth-child(2) {
    padding-bottom: 20px;
  }
}

@media (max-width: 767px) {
  .page-swiper .swiper-container .swiper-slide:nth-child(2) {
    padding-bottom: 10px;
  }
}

.page-swiper .swiper-container .swiper-slide:nth-child(3) {
  background-color: #fff;
  padding-bottom: 90px;
}

@media (max-width: 1600px) {
  .page-swiper .swiper-container .swiper-slide:nth-child(3) {
    padding-bottom: 74px;
  }
}

@media (max-width: 1359px) {
  .page-swiper .swiper-container .swiper-slide:nth-child(3) {
    padding-bottom: 58px;
  }
}

@media (max-width: 1024px) {
  .page-swiper .swiper-container .swiper-slide:nth-child(3) {
    padding-bottom: 42px;
  }
}

@media (max-width: 991px) {
  .page-swiper .swiper-container .swiper-slide:nth-child(3) {
    padding-bottom: 26px;
  }
}

@media (max-width: 767px) {
  .page-swiper .swiper-container .swiper-slide:nth-child(3) {
    padding-bottom: 10px;
  }
}

@media screen and (max-width: 1600px) {
  .page-swiper .swiper-container .swiper-slide:nth-child(3) {
    padding-bottom: 50px;
  }
}

@media screen and (max-width: 1600px) and (max-width: 1600px) {
  .page-swiper .swiper-container .swiper-slide:nth-child(3) {
    padding-bottom: 42px;
  }
}

@media screen and (max-width: 1600px) and (max-width: 1359px) {
  .page-swiper .swiper-container .swiper-slide:nth-child(3) {
    padding-bottom: 34px;
  }
}

@media screen and (max-width: 1600px) and (max-width: 1024px) {
  .page-swiper .swiper-container .swiper-slide:nth-child(3) {
    padding-bottom: 26px;
  }
}

@media screen and (max-width: 1600px) and (max-width: 991px) {
  .page-swiper .swiper-container .swiper-slide:nth-child(3) {
    padding-bottom: 18px;
  }
}

@media screen and (max-width: 1600px) and (max-width: 767px) {
  .page-swiper .swiper-container .swiper-slide:nth-child(3) {
    padding-bottom: 10px;
  }
}

.page-swiper .swiper-container .swiper-slide:nth-child(4) {
  background-color: #469fdd;
  padding-bottom: 90px;
}

@media (max-width: 1600px) {
  .page-swiper .swiper-container .swiper-slide:nth-child(4) {
    padding-bottom: 74px;
  }
}

@media (max-width: 1359px) {
  .page-swiper .swiper-container .swiper-slide:nth-child(4) {
    padding-bottom: 58px;
  }
}

@media (max-width: 1024px) {
  .page-swiper .swiper-container .swiper-slide:nth-child(4) {
    padding-bottom: 42px;
  }
}

@media (max-width: 991px) {
  .page-swiper .swiper-container .swiper-slide:nth-child(4) {
    padding-bottom: 26px;
  }
}

@media (max-width: 767px) {
  .page-swiper .swiper-container .swiper-slide:nth-child(4) {
    padding-bottom: 10px;
  }
}

@media screen and (max-width: 1600px) {
  .page-swiper .swiper-container .swiper-slide:nth-child(4) {
    padding-bottom: 50px;
  }
}

@media screen and (max-width: 1600px) and (max-width: 1600px) {
  .page-swiper .swiper-container .swiper-slide:nth-child(4) {
    padding-bottom: 42px;
  }
}

@media screen and (max-width: 1600px) and (max-width: 1359px) {
  .page-swiper .swiper-container .swiper-slide:nth-child(4) {
    padding-bottom: 34px;
  }
}

@media screen and (max-width: 1600px) and (max-width: 1024px) {
  .page-swiper .swiper-container .swiper-slide:nth-child(4) {
    padding-bottom: 26px;
  }
}

@media screen and (max-width: 1600px) and (max-width: 991px) {
  .page-swiper .swiper-container .swiper-slide:nth-child(4) {
    padding-bottom: 18px;
  }
}

@media screen and (max-width: 1600px) and (max-width: 767px) {
  .page-swiper .swiper-container .swiper-slide:nth-child(4) {
    padding-bottom: 10px;
  }
}

.page-swiper .swiper-container .swiper-slide:nth-child(5) {
  background-color: #fff;
  padding-bottom: 90px;
}

@media (max-width: 1600px) {
  .page-swiper .swiper-container .swiper-slide:nth-child(5) {
    padding-bottom: 74px;
  }
}

@media (max-width: 1359px) {
  .page-swiper .swiper-container .swiper-slide:nth-child(5) {
    padding-bottom: 58px;
  }
}

@media (max-width: 1024px) {
  .page-swiper .swiper-container .swiper-slide:nth-child(5) {
    padding-bottom: 42px;
  }
}

@media (max-width: 991px) {
  .page-swiper .swiper-container .swiper-slide:nth-child(5) {
    padding-bottom: 26px;
  }
}

@media (max-width: 767px) {
  .page-swiper .swiper-container .swiper-slide:nth-child(5) {
    padding-bottom: 10px;
  }
}

@media screen and (max-width: 1600px) {
  .page-swiper .swiper-container .swiper-slide:nth-child(5) {
    padding-bottom: 50px;
  }
}

@media screen and (max-width: 1600px) and (max-width: 1600px) {
  .page-swiper .swiper-container .swiper-slide:nth-child(5) {
    padding-bottom: 42px;
  }
}

@media screen and (max-width: 1600px) and (max-width: 1359px) {
  .page-swiper .swiper-container .swiper-slide:nth-child(5) {
    padding-bottom: 34px;
  }
}

@media screen and (max-width: 1600px) and (max-width: 1024px) {
  .page-swiper .swiper-container .swiper-slide:nth-child(5) {
    padding-bottom: 26px;
  }
}

@media screen and (max-width: 1600px) and (max-width: 991px) {
  .page-swiper .swiper-container .swiper-slide:nth-child(5) {
    padding-bottom: 18px;
  }
}

@media screen and (max-width: 1600px) and (max-width: 767px) {
  .page-swiper .swiper-container .swiper-slide:nth-child(5) {
    padding-bottom: 10px;
  }
}

.page-swiper .swiper-container .swiper-slide:nth-child(6) {
  background-color: #252d67;
  padding-left: 75px;
}

@media (max-width: 1600px) {
  .page-swiper .swiper-container .swiper-slide:nth-child(6) {
    padding-left: 62px;
  }
}

@media (max-width: 1359px) {
  .page-swiper .swiper-container .swiper-slide:nth-child(6) {
    padding-left: 49px;
  }
}

@media (max-width: 1024px) {
  .page-swiper .swiper-container .swiper-slide:nth-child(6) {
    padding-left: 36px;
  }
}

@media (max-width: 991px) {
  .page-swiper .swiper-container .swiper-slide:nth-child(6) {
    padding-left: 23px;
  }
}

@media (max-width: 767px) {
  .page-swiper .swiper-container .swiper-slide:nth-child(6) {
    padding-left: 10px;
  }
}

@media screen and (max-width: 768px) {
  .page-swiper .swiper-container .swiper-aside {
    padding-top: 10px;
  }
  .page-swiper .swiper-container .swiper-aside .more-link {
    bottom: 0;
    right: 10px;
  }
  .page-swiper .swiper-container .swiper-slide {
    padding-top: 90px;
  }
}

@media screen and (max-width: 768px) and (max-width: 1600px) {
  .page-swiper .swiper-container .swiper-slide {
    padding-top: 84px;
  }
}

@media screen and (max-width: 768px) and (max-width: 1359px) {
  .page-swiper .swiper-container .swiper-slide {
    padding-top: 78px;
  }
}

@media screen and (max-width: 768px) and (max-width: 1024px) {
  .page-swiper .swiper-container .swiper-slide {
    padding-top: 72px;
  }
}

@media screen and (max-width: 768px) and (max-width: 991px) {
  .page-swiper .swiper-container .swiper-slide {
    padding-top: 66px;
  }
}

@media screen and (max-width: 768px) and (max-width: 767px) {
  .page-swiper .swiper-container .swiper-slide {
    padding-top: 60px;
  }
}

.index-products .icon-list ul {
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  flex-wrap: wrap;
  position: relative;
}

.index-products .icon-list ul li {
  flex-shrink: 0;
  transition: all 0.2s;
  width: 33.33%;
  height: 380px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

@media (max-width: 1600px) {
  .index-products .icon-list ul li {
    height: 344px;
  }
}

@media (max-width: 1359px) {
  .index-products .icon-list ul li {
    height: 308px;
  }
}

@media (max-width: 1024px) {
  .index-products .icon-list ul li {
    height: 272px;
  }
}

@media (max-width: 991px) {
  .index-products .icon-list ul li {
    height: 236px;
  }
}

@media (max-width: 767px) {
  .index-products .icon-list ul li {
    height: 200px;
  }
}

@media screen and (max-width: 1600px) {
  .index-products .icon-list ul li {
    height: 320px;
  }
}

@media screen and (max-width: 1600px) and (max-width: 1600px) {
  .index-products .icon-list ul li {
    height: 296px;
  }
}

@media screen and (max-width: 1600px) and (max-width: 1359px) {
  .index-products .icon-list ul li {
    height: 272px;
  }
}

@media screen and (max-width: 1600px) and (max-width: 1024px) {
  .index-products .icon-list ul li {
    height: 248px;
  }
}

@media screen and (max-width: 1600px) and (max-width: 991px) {
  .index-products .icon-list ul li {
    height: 224px;
  }
}

@media screen and (max-width: 1600px) and (max-width: 767px) {
  .index-products .icon-list ul li {
    height: 200px;
  }
}

@media screen and (max-width: 1024px) {
  .index-products .icon-list ul li {
    height: 380px;
  }
}

@media screen and (max-width: 1024px) and (max-width: 1600px) {
  .index-products .icon-list ul li {
    height: 344px;
  }
}

@media screen and (max-width: 1024px) and (max-width: 1359px) {
  .index-products .icon-list ul li {
    height: 308px;
  }
}

@media screen and (max-width: 1024px) and (max-width: 1024px) {
  .index-products .icon-list ul li {
    height: 272px;
  }
}

@media screen and (max-width: 1024px) and (max-width: 991px) {
  .index-products .icon-list ul li {
    height: 236px;
  }
}

@media screen and (max-width: 1024px) and (max-width: 767px) {
  .index-products .icon-list ul li {
    height: 200px;
  }
}

.index-products .icon-list ul li > a {
  display: block;
  overflow: hidden;
}

.index-products .icon-list ul li:hover .bc {
  transform: scale(1.2);
}

.index-products .icon-list ul li a {
  display: block;
  height: 100%;
  box-sizing: border-box;
  font-family: 'Lato-Light';
  color: #fff;
  padding-top: 40px;
  padding-bottom: 40px;
  padding-left: 50px;
  font-size: 24px;
}

@media (max-width: 1600px) {
  .index-products .icon-list ul li a {
    padding-top: 35px;
  }
}

@media (max-width: 1359px) {
  .index-products .icon-list ul li a {
    padding-top: 30px;
  }
}

@media (max-width: 1024px) {
  .index-products .icon-list ul li a {
    padding-top: 25px;
  }
}

@media (max-width: 991px) {
  .index-products .icon-list ul li a {
    padding-top: 20px;
  }
}

@media (max-width: 767px) {
  .index-products .icon-list ul li a {
    padding-top: 15px;
  }
}

@media (max-width: 1600px) {
  .index-products .icon-list ul li a {
    padding-bottom: 35px;
  }
}

@media (max-width: 1359px) {
  .index-products .icon-list ul li a {
    padding-bottom: 30px;
  }
}

@media (max-width: 1024px) {
  .index-products .icon-list ul li a {
    padding-bottom: 25px;
  }
}

@media (max-width: 991px) {
  .index-products .icon-list ul li a {
    padding-bottom: 20px;
  }
}

@media (max-width: 767px) {
  .index-products .icon-list ul li a {
    padding-bottom: 15px;
  }
}

@media (max-width: 1600px) {
  .index-products .icon-list ul li a {
    padding-left: 44px;
  }
}

@media (max-width: 1359px) {
  .index-products .icon-list ul li a {
    padding-left: 38px;
  }
}

@media (max-width: 1024px) {
  .index-products .icon-list ul li a {
    padding-left: 32px;
  }
}

@media (max-width: 991px) {
  .index-products .icon-list ul li a {
    padding-left: 26px;
  }
}

@media (max-width: 767px) {
  .index-products .icon-list ul li a {
    padding-left: 20px;
  }
}

@media (max-width: 1600px) {
  .index-products .icon-list ul li a {
    font-size: 22px;
  }
}

@media (max-width: 1359px) {
  .index-products .icon-list ul li a {
    font-size: 20px;
  }
}

@media (max-width: 1024px) {
  .index-products .icon-list ul li a {
    font-size: 16px;
  }
}

.index-products .icon-list ul li a span {
  display: block;
  position: relative;
}

.index-products .icon-list ul li a span::after {
  content: '';
  display: block;
  position: absolute;
  top: 50%;
  background-color: #fff;
  transition: all 0.2s;
  transform: translateY(-50%) scaleX(0);
  transform-origin: right;
  left: -50px;
  width: 40px;
  height: 2px;
}

@media (max-width: 1600px) {
  .index-products .icon-list ul li a span::after {
    left: -44px;
  }
}

@media (max-width: 1359px) {
  .index-products .icon-list ul li a span::after {
    left: -38px;
  }
}

@media (max-width: 1024px) {
  .index-products .icon-list ul li a span::after {
    left: -32px;
  }
}

@media (max-width: 991px) {
  .index-products .icon-list ul li a span::after {
    left: -26px;
  }
}

@media (max-width: 767px) {
  .index-products .icon-list ul li a span::after {
    left: -20px;
  }
}

@media (max-width: 1600px) {
  .index-products .icon-list ul li a span::after {
    width: 34px;
  }
}

@media (max-width: 1359px) {
  .index-products .icon-list ul li a span::after {
    width: 28px;
  }
}

@media (max-width: 1024px) {
  .index-products .icon-list ul li a span::after {
    width: 22px;
  }
}

@media (max-width: 991px) {
  .index-products .icon-list ul li a span::after {
    width: 16px;
  }
}

@media (max-width: 767px) {
  .index-products .icon-list ul li a span::after {
    width: 10px;
  }
}

@media (max-width: 1600px) {
  .index-products .icon-list ul li a span::after {
    height: 1.8px;
  }
}

@media (max-width: 1359px) {
  .index-products .icon-list ul li a span::after {
    height: 1.6px;
  }
}

@media (max-width: 1024px) {
  .index-products .icon-list ul li a span::after {
    height: 1.4px;
  }
}

@media (max-width: 991px) {
  .index-products .icon-list ul li a span::after {
    height: 1.2px;
  }
}

@media (max-width: 767px) {
  .index-products .icon-list ul li a span::after {
    height: 1px;
  }
}

.index-products .icon-list ul li a .bac {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  height: 30%;
  max-height: 253px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  z-index: 1;
}

.index-products .icon-list ul li a .bc {
  transition: all .8s;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom center;
  position: absolute;
  width: 100%;
  height: 100%;
  height: 380px;
  top: 0;
  left: 0;
}

@media (max-width: 1600px) {
  .index-products .icon-list ul li a .bc {
    height: 344px;
  }
}

@media (max-width: 1359px) {
  .index-products .icon-list ul li a .bc {
    height: 308px;
  }
}

@media (max-width: 1024px) {
  .index-products .icon-list ul li a .bc {
    height: 272px;
  }
}

@media (max-width: 991px) {
  .index-products .icon-list ul li a .bc {
    height: 236px;
  }
}

@media (max-width: 767px) {
  .index-products .icon-list ul li a .bc {
    height: 200px;
  }
}

.index-products .icon-list ul li a .ti1 {
  z-index: 2;
  color: #ffffff;
  font-family: Lato-Bold;
  position: relative;
}

.index-products .icon-list ul li a .ti2 {
  width: auto;
  height: auto;
  position: relative;
  max-width: 170px;
  max-height: 90px;
  margin-top: 40px;
}

@media (max-width: 1024px) {
  .index-products .icon-list ul li a .ti2 {
    max-width: 100px;
  }
}

@media (max-width: 767px) {
  .index-products .icon-list ul li a .ti2 {
    max-width: 70px;
  }
}

@media (max-width: 1024px) {
  .index-products .icon-list ul li a .ti2 {
    max-height: 60px;
  }
}

@media (max-width: 767px) {
  .index-products .icon-list ul li a .ti2 {
    max-height: 40px;
  }
}

@media (max-width: 1600px) {
  .index-products .icon-list ul li a .ti2 {
    margin-top: 35px;
  }
}

@media (max-width: 1359px) {
  .index-products .icon-list ul li a .ti2 {
    margin-top: 30px;
  }
}

@media (max-width: 1024px) {
  .index-products .icon-list ul li a .ti2 {
    margin-top: 25px;
  }
}

@media (max-width: 991px) {
  .index-products .icon-list ul li a .ti2 {
    margin-top: 20px;
  }
}

@media (max-width: 767px) {
  .index-products .icon-list ul li a .ti2 {
    margin-top: 15px;
  }
}

.index-products .icon-list ul li a .ti3 {
  max-width: 50%;
  height: auto;
  position: absolute;
  bottom: 0;
  left: 0;
}

.index-products .procont {
  width: 100%;
  border-bottom: 1px solid #2d4883;
  margin-top: 30px;
  margin-bottom: 40px;
  padding-bottom: 0px;
}

@media (max-width: 1600px) {
  .index-products .procont {
    margin-top: 29px;
  }
}

@media (max-width: 1359px) {
  .index-products .procont {
    margin-top: 28px;
  }
}

@media (max-width: 1024px) {
  .index-products .procont {
    margin-top: 27px;
  }
}

@media (max-width: 991px) {
  .index-products .procont {
    margin-top: 26px;
  }
}

@media (max-width: 767px) {
  .index-products .procont {
    margin-top: 25px;
  }
}

@media (max-width: 1600px) {
  .index-products .procont {
    margin-bottom: 37px;
  }
}

@media (max-width: 1359px) {
  .index-products .procont {
    margin-bottom: 34px;
  }
}

@media (max-width: 1024px) {
  .index-products .procont {
    margin-bottom: 31px;
  }
}

@media (max-width: 991px) {
  .index-products .procont {
    margin-bottom: 28px;
  }
}

@media (max-width: 767px) {
  .index-products .procont {
    margin-bottom: 25px;
  }
}

@media screen and (max-width: 1600px) {
  .index-products .procont {
    margin-top: 10px;
    margin-bottom: 20px;
  }
}

.index-products .procont p {
  color: #fff;
  font-size: 28px;
  font-family: Lato-Light;
}

@media (max-width: 1600px) {
  .index-products .procont p {
    font-size: 26px;
  }
}

@media (max-width: 1359px) {
  .index-products .procont p {
    font-size: 22px;
  }
}

@media (max-width: 1024px) {
  .index-products .procont p {
    font-size: 18px;
  }
}

.index-products .search .title {
  color: #fff;
  font-family: 'Lato-Light';
  border-bottom: 1px solid rgba(70, 159, 221, 0.24);
  font-size: 28px;
  margin-top: 50px;
  margin-bottom: 45px;
  padding-bottom: 10px;
}

@media (max-width: 1600px) {
  .index-products .search .title {
    font-size: 26px;
  }
}

@media (max-width: 1359px) {
  .index-products .search .title {
    font-size: 22px;
  }
}

@media (max-width: 1024px) {
  .index-products .search .title {
    font-size: 18px;
  }
}

@media (max-width: 1600px) {
  .index-products .search .title {
    margin-top: 44px;
  }
}

@media (max-width: 1359px) {
  .index-products .search .title {
    margin-top: 38px;
  }
}

@media (max-width: 1024px) {
  .index-products .search .title {
    margin-top: 32px;
  }
}

@media (max-width: 991px) {
  .index-products .search .title {
    margin-top: 26px;
  }
}

@media (max-width: 767px) {
  .index-products .search .title {
    margin-top: 20px;
  }
}

@media (max-width: 1600px) {
  .index-products .search .title {
    margin-bottom: 38px;
  }
}

@media (max-width: 1359px) {
  .index-products .search .title {
    margin-bottom: 31px;
  }
}

@media (max-width: 1024px) {
  .index-products .search .title {
    margin-bottom: 24px;
  }
}

@media (max-width: 991px) {
  .index-products .search .title {
    margin-bottom: 17px;
  }
}

@media (max-width: 767px) {
  .index-products .search .title {
    margin-bottom: 10px;
  }
}

@media (max-width: 1600px) {
  .index-products .search .title {
    padding-bottom: 9px;
  }
}

@media (max-width: 1359px) {
  .index-products .search .title {
    padding-bottom: 8px;
  }
}

@media (max-width: 1024px) {
  .index-products .search .title {
    padding-bottom: 7px;
  }
}

@media (max-width: 991px) {
  .index-products .search .title {
    padding-bottom: 6px;
  }
}

@media (max-width: 767px) {
  .index-products .search .title {
    padding-bottom: 5px;
  }
}

.index-products .search .form-cont {
  align-items: center;
  justify-content: space-between;
  display: flex;
}

@media (max-width: 767px) {
  .index-products .search .form-cont {
    display: block;
  }
}

.index-products .search .form-cont .left-tab {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 25px;
  margin-bottom: 0;
}

@media (max-width: 1600px) {
  .index-products .search .form-cont .left-tab {
    margin-right: 23px;
  }
}

@media (max-width: 1359px) {
  .index-products .search .form-cont .left-tab {
    margin-right: 21px;
  }
}

@media (max-width: 1024px) {
  .index-products .search .form-cont .left-tab {
    margin-right: 19px;
  }
}

@media (max-width: 991px) {
  .index-products .search .form-cont .left-tab {
    margin-right: 17px;
  }
}

@media (max-width: 767px) {
  .index-products .search .form-cont .left-tab {
    margin-right: 15px;
  }
}

@media (max-width: 767px) {
  .index-products .search .form-cont .left-tab {
    margin-bottom: 10px;
  }
}

.index-products .search .form-cont .left-tab div {
  position: relative;
  font-family: 'Lato-Bold';
  color: rgba(255, 255, 255, 0.8);
  cursor: pointer;
  padding-left: 30px;
  font-size: 20px;
}

@media (max-width: 1600px) {
  .index-products .search .form-cont .left-tab div {
    padding-left: 28px;
  }
}

@media (max-width: 1359px) {
  .index-products .search .form-cont .left-tab div {
    padding-left: 26px;
  }
}

@media (max-width: 1024px) {
  .index-products .search .form-cont .left-tab div {
    padding-left: 24px;
  }
}

@media (max-width: 991px) {
  .index-products .search .form-cont .left-tab div {
    padding-left: 22px;
  }
}

@media (max-width: 767px) {
  .index-products .search .form-cont .left-tab div {
    padding-left: 20px;
  }
}

@media (max-width: 1600px) {
  .index-products .search .form-cont .left-tab div {
    font-size: 18px;
  }
}

@media (max-width: 1359px) {
  .index-products .search .form-cont .left-tab div {
    font-size: 16px;
  }
}

.index-products .search .form-cont .left-tab div.active::after {
  display: block;
}

.index-products .search .form-cont .left-tab div::after {
  content: '';
  display: none;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #469fdd;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 12px;
}

@media (max-width: 1600px) {
  .index-products .search .form-cont .left-tab div::after {
    left: 11px;
  }
}

@media (max-width: 1359px) {
  .index-products .search .form-cont .left-tab div::after {
    left: 10px;
  }
}

@media (max-width: 1024px) {
  .index-products .search .form-cont .left-tab div::after {
    left: 9px;
  }
}

@media (max-width: 991px) {
  .index-products .search .form-cont .left-tab div::after {
    left: 8px;
  }
}

@media (max-width: 767px) {
  .index-products .search .form-cont .left-tab div::after {
    left: 7px;
  }
}

.index-products .search .form-cont .right-form {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.index-products .search .form-cont .right-form .select {
  flex-shrink: 0;
  width: 62%;
}

.index-products .search .form-cont .right-form .select .select-item {
  position: relative;
  width: 100%;
  height: 100%;
  display: none;
  font-size: 18px;
}

@media (max-width: 1600px) {
  .index-products .search .form-cont .right-form .select .select-item {
    font-size: 16px;
  }
}

@media (max-width: 1359px) {
  .index-products .search .form-cont .right-form .select .select-item {
    font-size: 14px;
  }
}

.index-products .search .form-cont .right-form .select .select-item.active input {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.index-products .search .form-cont .right-form .select .select-item::after {
  content: '\e62d';
  font-family: 'iconfont' !important;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: block;
  z-index: 1;
  color: #5fb4ef;
  font-size: 24px;
  right: 10px;
}

@media (max-width: 1600px) {
  .index-products .search .form-cont .right-form .select .select-item::after {
    font-size: 23.2px;
  }
}

@media (max-width: 1359px) {
  .index-products .search .form-cont .right-form .select .select-item::after {
    font-size: 22.4px;
  }
}

@media (max-width: 1024px) {
  .index-products .search .form-cont .right-form .select .select-item::after {
    font-size: 21.6px;
  }
}

@media (max-width: 991px) {
  .index-products .search .form-cont .right-form .select .select-item::after {
    font-size: 20.8px;
  }
}

@media (max-width: 767px) {
  .index-products .search .form-cont .right-form .select .select-item::after {
    font-size: 20px;
  }
}

@media (max-width: 767px) {
  .index-products .search .form-cont .right-form .select .select-item::after {
    right: 0px;
  }
}

.index-products .search .form-cont .right-form .select .select-item input {
  box-sizing: border-box;
  width: 100%;
  outline: none;
  background: none;
  overflow: hidden;
  width: 100%;
  height: 100%;
  color: rgba(255, 255, 255, 0.8);
  cursor: pointer;
  border: 1px solid #5fb4ef;
  border-radius: 5px;
  padding-left: 25px;
  padding-right: 15px;
}

@media (max-width: 1600px) {
  .index-products .search .form-cont .right-form .select .select-item input {
    padding-left: 23px;
  }
}

@media (max-width: 1359px) {
  .index-products .search .form-cont .right-form .select .select-item input {
    padding-left: 21px;
  }
}

@media (max-width: 1024px) {
  .index-products .search .form-cont .right-form .select .select-item input {
    padding-left: 19px;
  }
}

@media (max-width: 991px) {
  .index-products .search .form-cont .right-form .select .select-item input {
    padding-left: 17px;
  }
}

@media (max-width: 767px) {
  .index-products .search .form-cont .right-form .select .select-item input {
    padding-left: 15px;
  }
}

@media (max-width: 767px) {
  .index-products .search .form-cont .right-form .select .select-item input {
    padding-right: 15px;
  }
}

.index-products .search .form-cont .right-form .select .select-item input::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.8);
}

.index-products .search .form-cont .right-form .select .select-item input:-moz-placeholder {
  color: rgba(255, 255, 255, 0.8);
}

.index-products .search .form-cont .right-form .select .select-item input::-moz-placeholder {
  color: rgba(255, 255, 255, 0.8);
}

.index-products .search .form-cont .right-form .select .select-item input:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.8);
}

.index-products .search .form-cont .right-form .select .select-item .selet-list {
  position: absolute;
  bottom: calc(100% - 1px);
  left: 0;
  width: 100%;
  border: 1px solid #5fb4ef;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background-color: #252d67;
  color: rgba(255, 255, 255, 0.8);
  display: none;
  overflow-y: auto;
  max-height: 600px;
}

@media (max-width: 991px) {
  .index-products .search .form-cont .right-form .select .select-item .selet-list {
    max-height: 300px;
  }
}

.index-products .search .form-cont .right-form .select .select-item .selet-list ul li {
  transition: all 0.2s;
  cursor: pointer;
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 10px;
  padding-bottom: 5px;
}

@media (max-width: 1600px) {
  .index-products .search .form-cont .right-form .select .select-item .selet-list ul li {
    padding-left: 23px;
  }
}

@media (max-width: 1359px) {
  .index-products .search .form-cont .right-form .select .select-item .selet-list ul li {
    padding-left: 21px;
  }
}

@media (max-width: 1024px) {
  .index-products .search .form-cont .right-form .select .select-item .selet-list ul li {
    padding-left: 19px;
  }
}

@media (max-width: 991px) {
  .index-products .search .form-cont .right-form .select .select-item .selet-list ul li {
    padding-left: 17px;
  }
}

@media (max-width: 767px) {
  .index-products .search .form-cont .right-form .select .select-item .selet-list ul li {
    padding-left: 15px;
  }
}

@media (max-width: 1600px) {
  .index-products .search .form-cont .right-form .select .select-item .selet-list ul li {
    padding-right: 23px;
  }
}

@media (max-width: 1359px) {
  .index-products .search .form-cont .right-form .select .select-item .selet-list ul li {
    padding-right: 21px;
  }
}

@media (max-width: 1024px) {
  .index-products .search .form-cont .right-form .select .select-item .selet-list ul li {
    padding-right: 19px;
  }
}

@media (max-width: 991px) {
  .index-products .search .form-cont .right-form .select .select-item .selet-list ul li {
    padding-right: 17px;
  }
}

@media (max-width: 767px) {
  .index-products .search .form-cont .right-form .select .select-item .selet-list ul li {
    padding-right: 15px;
  }
}

@media (max-width: 1600px) {
  .index-products .search .form-cont .right-form .select .select-item .selet-list ul li {
    padding-top: 5px;
  }
}

@media (max-width: 1600px) {
  .index-products .search .form-cont .right-form .select .select-item .selet-list ul li {
    padding-bottom: 5px;
  }
}

@media (max-width: 1359px) {
  .index-products .search .form-cont .right-form .select .select-item .selet-list ul li {
    padding-bottom: 5px;
  }
}

@media (max-width: 1024px) {
  .index-products .search .form-cont .right-form .select .select-item .selet-list ul li {
    padding-bottom: 5px;
  }
}

@media (max-width: 991px) {
  .index-products .search .form-cont .right-form .select .select-item .selet-list ul li {
    padding-bottom: 5px;
  }
}

@media (max-width: 767px) {
  .index-products .search .form-cont .right-form .select .select-item .selet-list ul li {
    padding-bottom: 5px;
  }
}

.index-products .search .form-cont .right-form .select .select-item .selet-list ul li:hover {
  background-color: #348fce;
}

.index-products .search .form-cont .right-form .button {
  flex-shrink: 0;
  width: 36.2%;
  height: 60px;
}

@media (max-width: 1600px) {
  .index-products .search .form-cont .right-form .button {
    height: 56px;
  }
}

@media (max-width: 1359px) {
  .index-products .search .form-cont .right-form .button {
    height: 52px;
  }
}

@media (max-width: 1024px) {
  .index-products .search .form-cont .right-form .button {
    height: 48px;
  }
}

@media (max-width: 991px) {
  .index-products .search .form-cont .right-form .button {
    height: 44px;
  }
}

@media (max-width: 767px) {
  .index-products .search .form-cont .right-form .button {
    height: 40px;
  }
}

.index-products .search .form-cont .right-form .button button {
  width: 100%;
  height: 100%;
  background-color: #469fdd;
  outline: none;
  border: none;
  color: #fff;
  cursor: pointer;
  font-family: 'Lato-Bold';
  transition: all 0.2s;
  font-size: 20px;
}

@media (max-width: 1600px) {
  .index-products .search .form-cont .right-form .button button {
    font-size: 18px;
  }
}

@media (max-width: 1359px) {
  .index-products .search .form-cont .right-form .button button {
    font-size: 16px;
  }
}

@media (max-width: 767px) {
  .index-products .search .form-cont .right-form .button button span {
    display: none;
  }
}

.index-products .search .form-cont .right-form .button button:hover {
  background-color: #348fce;
}

.index-technology ul {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  border-top: 2px solid #469fdd;
  border-left: 1px solid #ededed;
  height: 100%;
}

@media (max-width: 991px) {
  .index-technology ul {
    height: 80%;
  }
}

@media (max-width: 767px) {
  .index-technology ul {
    height: auto;
  }
}

.index-technology ul li {
  border-right: 1px solid #ededed;
  border-bottom: 1px solid #ededed;
  position: relative;
  width: 33.33%;
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 58px;
  padding-bottom: 80px;
}

@media (max-width: 1024px) {
  .index-technology ul li {
    width: 33.33%;
  }
}

@media (max-width: 767px) {
  .index-technology ul li {
    width: 100%;
  }
}

@media (max-width: 1600px) {
  .index-technology ul li {
    padding-left: 34px;
  }
}

@media (max-width: 1359px) {
  .index-technology ul li {
    padding-left: 28px;
  }
}

@media (max-width: 1024px) {
  .index-technology ul li {
    padding-left: 22px;
  }
}

@media (max-width: 991px) {
  .index-technology ul li {
    padding-left: 16px;
  }
}

@media (max-width: 767px) {
  .index-technology ul li {
    padding-left: 10px;
  }
}

@media (max-width: 1600px) {
  .index-technology ul li {
    padding-right: 34px;
  }
}

@media (max-width: 1359px) {
  .index-technology ul li {
    padding-right: 28px;
  }
}

@media (max-width: 1024px) {
  .index-technology ul li {
    padding-right: 22px;
  }
}

@media (max-width: 991px) {
  .index-technology ul li {
    padding-right: 16px;
  }
}

@media (max-width: 767px) {
  .index-technology ul li {
    padding-right: 10px;
  }
}

@media (max-width: 1600px) {
  .index-technology ul li {
    padding-top: 48.4px;
  }
}

@media (max-width: 1359px) {
  .index-technology ul li {
    padding-top: 38.8px;
  }
}

@media (max-width: 1024px) {
  .index-technology ul li {
    padding-top: 29.2px;
  }
}

@media (max-width: 991px) {
  .index-technology ul li {
    padding-top: 19.6px;
  }
}

@media (max-width: 767px) {
  .index-technology ul li {
    padding-top: 10px;
  }
}

@media (max-width: 1600px) {
  .index-technology ul li {
    padding-bottom: 66px;
  }
}

@media (max-width: 1359px) {
  .index-technology ul li {
    padding-bottom: 52px;
  }
}

@media (max-width: 1024px) {
  .index-technology ul li {
    padding-bottom: 38px;
  }
}

@media (max-width: 991px) {
  .index-technology ul li {
    padding-bottom: 24px;
  }
}

@media (max-width: 767px) {
  .index-technology ul li {
    padding-bottom: 10px;
  }
}

.index-technology ul li:nth-child(1), .index-technology ul li:nth-child(2) {
  height: 100%;
}

@media (max-width: 1024px) {
  .index-technology ul li:nth-child(1), .index-technology ul li:nth-child(2) {
    height: 100%;
  }
}

@media (max-width: 767px) {
  .index-technology ul li:nth-child(1), .index-technology ul li:nth-child(2) {
    height: auto;
  }
}

.index-technology ul li:nth-child(3), .index-technology ul li:nth-child(4) {
  height: 50%;
}

@media (max-width: 767px) {
  .index-technology ul li:nth-child(3), .index-technology ul li:nth-child(4) {
    height: auto;
  }
}

.index-technology ul li:nth-child(3) .item-img, .index-technology ul li:nth-child(4) .item-img {
  display: none;
}

@media (max-width: 767px) {
  .index-technology ul li:nth-child(3) .item-img, .index-technology ul li:nth-child(4) .item-img {
    display: block;
  }
}

.index-technology ul li:nth-child(3) .item-link, .index-technology ul li:nth-child(4) .item-link {
  bottom: 30px;
}

@media (max-width: 1600px) {
  .index-technology ul li:nth-child(3) .item-link, .index-technology ul li:nth-child(4) .item-link {
    bottom: 26px;
  }
}

@media (max-width: 1359px) {
  .index-technology ul li:nth-child(3) .item-link, .index-technology ul li:nth-child(4) .item-link {
    bottom: 22px;
  }
}

@media (max-width: 1024px) {
  .index-technology ul li:nth-child(3) .item-link, .index-technology ul li:nth-child(4) .item-link {
    bottom: 18px;
  }
}

@media (max-width: 991px) {
  .index-technology ul li:nth-child(3) .item-link, .index-technology ul li:nth-child(4) .item-link {
    bottom: 14px;
  }
}

@media (max-width: 767px) {
  .index-technology ul li:nth-child(3) .item-link, .index-technology ul li:nth-child(4) .item-link {
    bottom: 10px;
  }
}

@media (max-width: 1600px) {
  .index-technology ul li:nth-child(3) .item-link, .index-technology ul li:nth-child(4) .item-link {
    bottom: 10px;
  }
}

@media (max-width: 767px) {
  .index-technology ul li:nth-child(3), .index-technology ul li:nth-child(4) {
    display: none;
  }
}

.index-technology ul li:nth-child(4) {
  bottom: 0;
  right: 0;
  position: absolute;
}

@media (max-width: 767px) {
  .index-technology ul li:nth-child(4) {
    position: relative;
  }
}

.index-technology ul li:hover .item-img img {
  transform: scale(1.05);
}

.index-technology ul li .item-img {
  overflow: hidden;
  display: block;
  margin-bottom: 35px;
  max-height: 260px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 1600px) {
  .index-technology ul li .item-img {
    margin-bottom: 30px;
  }
}

@media (max-width: 1359px) {
  .index-technology ul li .item-img {
    margin-bottom: 25px;
  }
}

@media (max-width: 1024px) {
  .index-technology ul li .item-img {
    margin-bottom: 20px;
  }
}

@media (max-width: 991px) {
  .index-technology ul li .item-img {
    margin-bottom: 15px;
  }
}

@media (max-width: 767px) {
  .index-technology ul li .item-img {
    margin-bottom: 10px;
  }
}

@media (max-width: 767px) {
  .index-technology ul li .item-img {
    height: 100px;
  }
}

@media (max-width: 1600px) {
  .index-technology ul li .item-img {
    max-height: 150px;
  }
}

@media (max-width: 767px) {
  .index-technology ul li .item-img {
    max-height: 100px;
  }
}

.index-technology ul li .item-img img {
  display: block;
  height: auto;
  transition: all 0.2s;
  width: 100%;
}

@media (max-width: 767px) {
  .index-technology ul li .item-img img {
    margin: 0 auto;
  }
}

.index-technology ul li .item-title {
  font-family: 'Lato-Light';
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 1.2;
  margin-bottom: 10px;
}

@media (max-width: 767px) {
  .index-technology ul li .item-title {
    text-align: center;
  }
}

@media (max-width: 1600px) {
  .index-technology ul li .item-title {
    margin-bottom: 9px;
  }
}

@media (max-width: 1359px) {
  .index-technology ul li .item-title {
    margin-bottom: 8px;
  }
}

@media (max-width: 1024px) {
  .index-technology ul li .item-title {
    margin-bottom: 7px;
  }
}

@media (max-width: 991px) {
  .index-technology ul li .item-title {
    margin-bottom: 6px;
  }
}

@media (max-width: 767px) {
  .index-technology ul li .item-title {
    margin-bottom: 5px;
  }
}

@media (max-width: 1024px) {
  .index-technology ul li .item-title {
    margin-bottom: 4px;
  }
}

.index-technology ul li .item-desc {
  font-family: 'Lato-Bold';
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: 1.5;
  -webkit-line-clamp: 3;
  font-size: 18px;
}

@media (max-width: 1024px) {
  .index-technology ul li .item-desc {
    line-height: 1.1;
  }
}

@media (max-width: 991px) {
  .index-technology ul li .item-desc {
    line-height: 1.5;
  }
}

@media (max-width: 767px) {
  .index-technology ul li .item-desc {
    line-height: 1.2;
  }
}

@media (max-width: 767px) {
  .index-technology ul li .item-desc {
    -webkit-line-clamp: 2;
  }
}

@media (max-width: 1024px) {
  .index-technology ul li .item-desc {
    -webkit-line-clamp: 2;
  }
}

@media (max-width: 1600px) {
  .index-technology ul li .item-desc {
    font-size: 16px;
  }
}

@media (max-width: 1359px) {
  .index-technology ul li .item-desc {
    font-size: 14px;
  }
}

.index-technology ul li .item-desc.minheight {
  -webkit-line-clamp: 2 !important;
}

.index-technology ul li .item-link {
  position: absolute;
  left: 0;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  color: #0082d0;
  display: flex;
  bottom: 50px;
  padding-left: 40px;
  padding-right: 40px;
}

@media (max-width: 767px) {
  .index-technology ul li .item-link {
    display: none;
  }
}

@media (max-width: 1600px) {
  .index-technology ul li .item-link {
    bottom: 40px;
  }
}

@media (max-width: 1359px) {
  .index-technology ul li .item-link {
    bottom: 30px;
  }
}

@media (max-width: 1024px) {
  .index-technology ul li .item-link {
    bottom: 20px;
  }
}

@media (max-width: 991px) {
  .index-technology ul li .item-link {
    bottom: 10px;
  }
}

@media (max-width: 767px) {
  .index-technology ul li .item-link {
    bottom: 0px;
  }
}

@media (max-width: 1600px) {
  .index-technology ul li .item-link {
    bottom: 20px;
  }
}

@media (max-width: 1024px) {
  .index-technology ul li .item-link {
    bottom: 0;
  }
}

@media (max-width: 991px) {
  .index-technology ul li .item-link {
    bottom: 50px;
  }
}

@media (max-width: 991px) and (max-width: 1600px) {
  .index-technology ul li .item-link {
    bottom: 40px;
  }
}

@media (max-width: 991px) and (max-width: 1359px) {
  .index-technology ul li .item-link {
    bottom: 30px;
  }
}

@media (max-width: 991px) and (max-width: 1024px) {
  .index-technology ul li .item-link {
    bottom: 20px;
  }
}

@media (max-width: 991px) and (max-width: 991px) {
  .index-technology ul li .item-link {
    bottom: 10px;
  }
}

@media (max-width: 991px) and (max-width: 767px) {
  .index-technology ul li .item-link {
    bottom: 0px;
  }
}

@media (max-width: 1600px) {
  .index-technology ul li .item-link {
    padding-left: 34px;
  }
}

@media (max-width: 1359px) {
  .index-technology ul li .item-link {
    padding-left: 28px;
  }
}

@media (max-width: 1024px) {
  .index-technology ul li .item-link {
    padding-left: 22px;
  }
}

@media (max-width: 991px) {
  .index-technology ul li .item-link {
    padding-left: 16px;
  }
}

@media (max-width: 767px) {
  .index-technology ul li .item-link {
    padding-left: 10px;
  }
}

@media (max-width: 1600px) {
  .index-technology ul li .item-link {
    padding-right: 34px;
  }
}

@media (max-width: 1359px) {
  .index-technology ul li .item-link {
    padding-right: 28px;
  }
}

@media (max-width: 1024px) {
  .index-technology ul li .item-link {
    padding-right: 22px;
  }
}

@media (max-width: 991px) {
  .index-technology ul li .item-link {
    padding-right: 16px;
  }
}

@media (max-width: 767px) {
  .index-technology ul li .item-link {
    padding-right: 10px;
  }
}

.index-technology ul li .item-link:hover .icon {
  transform: rotate(90deg);
}

.index-technology ul li .item-link .text {
  font-size: 14px;
  font-family: 'Lato-Bold';
}

.index-technology ul li .item-link .icon {
  transition: all 0.2s;
}

.index-technology ul li .item-link .icon .iconfont {
  font-size: 18px;
}

@media screen and (width: 1366px) {
  .index-technology ul li {
    padding-top: 20px;
  }
}

@media screen and (width: 768px) {
  .index-technology {
    padding-bottom: 0px;
  }
}

.index-services ul {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  display: flex;
  height: 100%;
}

@media (max-width: 767px) {
  .index-services ul {
    display: block;
  }
}

@media (max-width: 991px) {
  .index-services ul {
    height: 80%;
  }
}

@media (max-width: 767px) {
  .index-services ul {
    height: auto;
  }
}

.index-services ul li {
  border: 1px solid #fff;
  position: relative;
  transition: all 0.2s;
  width: 50%;
  height: 100%;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 30px;
  padding-top: 60px;
}

@media (max-width: 767px) {
  .index-services ul li {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .index-services ul li {
    height: auto;
  }
}

@media (max-width: 1600px) {
  .index-services ul li {
    padding-left: 26px;
  }
}

@media (max-width: 1359px) {
  .index-services ul li {
    padding-left: 22px;
  }
}

@media (max-width: 1024px) {
  .index-services ul li {
    padding-left: 18px;
  }
}

@media (max-width: 991px) {
  .index-services ul li {
    padding-left: 14px;
  }
}

@media (max-width: 767px) {
  .index-services ul li {
    padding-left: 10px;
  }
}

@media (max-width: 1600px) {
  .index-services ul li {
    padding-right: 26px;
  }
}

@media (max-width: 1359px) {
  .index-services ul li {
    padding-right: 22px;
  }
}

@media (max-width: 1024px) {
  .index-services ul li {
    padding-right: 18px;
  }
}

@media (max-width: 991px) {
  .index-services ul li {
    padding-right: 14px;
  }
}

@media (max-width: 767px) {
  .index-services ul li {
    padding-right: 10px;
  }
}

@media (max-width: 1600px) {
  .index-services ul li {
    padding-bottom: 26px;
  }
}

@media (max-width: 1359px) {
  .index-services ul li {
    padding-bottom: 22px;
  }
}

@media (max-width: 1024px) {
  .index-services ul li {
    padding-bottom: 18px;
  }
}

@media (max-width: 991px) {
  .index-services ul li {
    padding-bottom: 14px;
  }
}

@media (max-width: 767px) {
  .index-services ul li {
    padding-bottom: 10px;
  }
}

@media (max-width: 1600px) {
  .index-services ul li {
    padding-top: 50px;
  }
}

@media (max-width: 1359px) {
  .index-services ul li {
    padding-top: 40px;
  }
}

@media (max-width: 1024px) {
  .index-services ul li {
    padding-top: 30px;
  }
}

@media (max-width: 991px) {
  .index-services ul li {
    padding-top: 20px;
  }
}

@media (max-width: 767px) {
  .index-services ul li {
    padding-top: 10px;
  }
}

@media (max-width: 1600px) {
  .index-services ul li {
    padding-top: 40px;
  }
}

@media (max-width: 1600px) and (max-width: 1600px) {
  .index-services ul li {
    padding-top: 34px;
  }
}

@media (max-width: 1600px) and (max-width: 1359px) {
  .index-services ul li {
    padding-top: 28px;
  }
}

@media (max-width: 1600px) and (max-width: 1024px) {
  .index-services ul li {
    padding-top: 22px;
  }
}

@media (max-width: 1600px) and (max-width: 991px) {
  .index-services ul li {
    padding-top: 16px;
  }
}

@media (max-width: 1600px) and (max-width: 767px) {
  .index-services ul li {
    padding-top: 10px;
  }
}

.index-services ul li:hover {
  background-color: #fff;
}

.index-services ul li:hover .item-desc {
  color: #469fdd;
}

.index-services ul li:hover .item-img img {
  transform: scale(1.05);
}

.index-services ul li:first-child {
  border-right: none;
  border-bottom: 1px solid #fff;
}

@media (max-width: 767px) {
  .index-services ul li:first-child {
    border-right: 1px solid #fff;
  }
}

@media (max-width: 767px) {
  .index-services ul li:first-child {
    border-bottom: none;
  }
}

.index-services ul li .item-title {
  color: #252d67;
  font-family: 'Lato-Light';
  font-size: 26px;
  margin-bottom: 45px;
}

@media (max-width: 767px) {
  .index-services ul li .item-title {
    text-align: center;
  }
}

@media (max-width: 1600px) {
  .index-services ul li .item-title {
    font-size: 22px;
  }
}

@media (max-width: 1359px) {
  .index-services ul li .item-title {
    font-size: 20px;
  }
}

@media (max-width: 1024px) {
  .index-services ul li .item-title {
    font-size: 16px;
  }
}

@media (max-width: 1600px) {
  .index-services ul li .item-title {
    margin-bottom: 37px;
  }
}

@media (max-width: 1359px) {
  .index-services ul li .item-title {
    margin-bottom: 29px;
  }
}

@media (max-width: 1024px) {
  .index-services ul li .item-title {
    margin-bottom: 21px;
  }
}

@media (max-width: 991px) {
  .index-services ul li .item-title {
    margin-bottom: 13px;
  }
}

@media (max-width: 767px) {
  .index-services ul li .item-title {
    margin-bottom: 5px;
  }
}

@media (max-width: 1600px) {
  .index-services ul li .item-title {
    margin-bottom: 25px;
  }
}

@media (max-width: 1600px) and (max-width: 1600px) {
  .index-services ul li .item-title {
    margin-bottom: 21px;
  }
}

@media (max-width: 1600px) and (max-width: 1359px) {
  .index-services ul li .item-title {
    margin-bottom: 17px;
  }
}

@media (max-width: 1600px) and (max-width: 1024px) {
  .index-services ul li .item-title {
    margin-bottom: 13px;
  }
}

@media (max-width: 1600px) and (max-width: 991px) {
  .index-services ul li .item-title {
    margin-bottom: 9px;
  }
}

@media (max-width: 1600px) and (max-width: 767px) {
  .index-services ul li .item-title {
    margin-bottom: 5px;
  }
}

.index-services ul li .item-desc {
  color: #fff;
  font-family: 'Lato-Bold';
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  transition: all 0.2s;
  -webkit-line-clamp: 3;
  font-size: 18px;
}

@media (max-width: 767px) {
  .index-services ul li .item-desc {
    line-height: 1.2;
  }
}

@media (max-width: 767px) {
  .index-services ul li .item-desc {
    -webkit-line-clamp: 2;
  }
}

@media (max-width: 1024px) {
  .index-services ul li .item-desc {
    -webkit-line-clamp: 2;
  }
}

@media (max-width: 1600px) {
  .index-services ul li .item-desc {
    font-size: 16px;
  }
}

@media (max-width: 1359px) {
  .index-services ul li .item-desc {
    font-size: 14px;
  }
}

.index-services ul li .item-desc.minheight {
  -webkit-line-clamp: 2 !important;
}

.index-services ul li .item-img {
  overflow: hidden;
  margin-top: 0;
  display: block;
  position: absolute;
  bottom: 30px;
  left: 30px;
  right: 30px;
}

@media (max-width: 767px) {
  .index-services ul li .item-img {
    margin-top: 10px;
  }
}

@media (max-width: 767px) {
  .index-services ul li .item-img {
    position: static;
  }
}

@media (max-width: 1600px) {
  .index-services ul li .item-img {
    bottom: 26px;
  }
}

@media (max-width: 1359px) {
  .index-services ul li .item-img {
    bottom: 22px;
  }
}

@media (max-width: 1024px) {
  .index-services ul li .item-img {
    bottom: 18px;
  }
}

@media (max-width: 991px) {
  .index-services ul li .item-img {
    bottom: 14px;
  }
}

@media (max-width: 767px) {
  .index-services ul li .item-img {
    bottom: 10px;
  }
}

@media (max-width: 1600px) {
  .index-services ul li .item-img {
    left: 26px;
  }
}

@media (max-width: 1359px) {
  .index-services ul li .item-img {
    left: 22px;
  }
}

@media (max-width: 1024px) {
  .index-services ul li .item-img {
    left: 18px;
  }
}

@media (max-width: 991px) {
  .index-services ul li .item-img {
    left: 14px;
  }
}

@media (max-width: 767px) {
  .index-services ul li .item-img {
    left: 10px;
  }
}

@media (max-width: 1600px) {
  .index-services ul li .item-img {
    right: 26px;
  }
}

@media (max-width: 1359px) {
  .index-services ul li .item-img {
    right: 22px;
  }
}

@media (max-width: 1024px) {
  .index-services ul li .item-img {
    right: 18px;
  }
}

@media (max-width: 991px) {
  .index-services ul li .item-img {
    right: 14px;
  }
}

@media (max-width: 767px) {
  .index-services ul li .item-img {
    right: 10px;
  }
}

.index-services ul li .item-img img {
  display: block;
  height: auto;
  transition: all 0.2s;
  width: 100%;
  max-height: 260px;
}

@media screen and (max-width: 1024px) {
  .index-services ul li .item-img img {
    height: 105px;
  }
}

@media screen and (max-width: 991px) {
  .index-services ul li .item-img img {
    height: auto;
  }
}

@media (max-width: 767px) {
  .index-services ul li .item-img img {
    margin: 0 auto;
  }
}

@media (max-width: 767px) {
  .index-services ul li .item-img img {
    width: auto;
  }
}

@media (max-width: 767px) {
  .index-services ul li .item-img img {
    max-height: 100px;
  }
}

@media screen and (width: 1366px) {
  .index-services ul li {
    padding-top: 20px;
  }
  .index-services ul li .item-title {
    margin-bottom: 20px;
  }
}

.index-about .cont {
  position: relative;
  height: 100%;
}

@media (max-width: 991px) {
  .index-about .cont {
    height: 80%;
  }
}

@media (max-width: 767px) {
  .index-about .cont {
    height: auto;
  }
}

.index-about .cont::after {
  content: '';
  width: 36.4%;
  height: 100%;
  background: #252d67;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 0;
  display: block;
}

@media (max-width: 767px) {
  .index-about .cont::after {
    display: none;
  }
}

.index-about .cont .cont1 {
  align-items: flex-start;
  justify-content: space-between;
  position: relative;
  z-index: 1;
  display: flex;
}

@media (max-width: 767px) {
  .index-about .cont .cont1 {
    display: block;
  }
}

.index-about .cont .cont1 .left {
  flex-shrink: 0;
  box-sizing: border-box;
  background: #252d67;
  padding: 30px;
  width: 36.4%;
}

@media (max-width: 1600px) {
  .index-about .cont .cont1 .left {
    padding: 26px;
  }
}

@media (max-width: 1359px) {
  .index-about .cont .cont1 .left {
    padding: 22px;
  }
}

@media (max-width: 1024px) {
  .index-about .cont .cont1 .left {
    padding: 18px;
  }
}

@media (max-width: 991px) {
  .index-about .cont .cont1 .left {
    padding: 14px;
  }
}

@media (max-width: 767px) {
  .index-about .cont .cont1 .left {
    padding: 10px;
  }
}

@media (max-width: 767px) {
  .index-about .cont .cont1 .left {
    width: 100%;
  }
}

.index-about .cont .cont1 .left div {
  font-family: 'Lato-Light';
  line-height: 1.2;
  background: url(./images/about_img1.png) no-repeat right bottom #fff;
  padding-top: 60px;
  padding-bottom: 60px;
  padding-left: 25px;
  font-size: 22px;
}

@media (max-width: 1024px) {
  .index-about .cont .cont1 .left div {
    background-size: auto 80%;
  }
}

@media (max-width: 1600px) {
  .index-about .cont .cont1 .left div {
    padding-top: 52px;
  }
}

@media (max-width: 1359px) {
  .index-about .cont .cont1 .left div {
    padding-top: 44px;
  }
}

@media (max-width: 1024px) {
  .index-about .cont .cont1 .left div {
    padding-top: 36px;
  }
}

@media (max-width: 991px) {
  .index-about .cont .cont1 .left div {
    padding-top: 28px;
  }
}

@media (max-width: 767px) {
  .index-about .cont .cont1 .left div {
    padding-top: 20px;
  }
}

@media (max-width: 1600px) {
  .index-about .cont .cont1 .left div {
    padding-bottom: 52px;
  }
}

@media (max-width: 1359px) {
  .index-about .cont .cont1 .left div {
    padding-bottom: 44px;
  }
}

@media (max-width: 1024px) {
  .index-about .cont .cont1 .left div {
    padding-bottom: 36px;
  }
}

@media (max-width: 991px) {
  .index-about .cont .cont1 .left div {
    padding-bottom: 28px;
  }
}

@media (max-width: 767px) {
  .index-about .cont .cont1 .left div {
    padding-bottom: 20px;
  }
}

@media (max-width: 1600px) {
  .index-about .cont .cont1 .left div {
    padding-left: 23px;
  }
}

@media (max-width: 1359px) {
  .index-about .cont .cont1 .left div {
    padding-left: 21px;
  }
}

@media (max-width: 1024px) {
  .index-about .cont .cont1 .left div {
    padding-left: 19px;
  }
}

@media (max-width: 991px) {
  .index-about .cont .cont1 .left div {
    padding-left: 17px;
  }
}

@media (max-width: 767px) {
  .index-about .cont .cont1 .left div {
    padding-left: 15px;
  }
}

@media (max-width: 1600px) {
  .index-about .cont .cont1 .left div {
    font-size: 20px;
  }
}

@media (max-width: 1359px) {
  .index-about .cont .cont1 .left div {
    font-size: 18px;
  }
}

@media (max-width: 1024px) {
  .index-about .cont .cont1 .left div {
    font-size: 16px;
  }
}

.index-about .cont .cont1 .right {
  flex-shrink: 0;
  box-sizing: border-box;
  margin-top: 0;
  margin-bottom: 0;
  padding: 40px;
  padding-top: 30px;
  width: 63.6%;
}

@media (max-width: 767px) {
  .index-about .cont .cont1 .right {
    margin-top: 15px;
  }
}

@media (max-width: 767px) {
  .index-about .cont .cont1 .right {
    margin-bottom: 15px;
  }
}

@media (max-width: 1600px) {
  .index-about .cont .cont1 .right {
    padding: 34px;
  }
}

@media (max-width: 1359px) {
  .index-about .cont .cont1 .right {
    padding: 28px;
  }
}

@media (max-width: 1024px) {
  .index-about .cont .cont1 .right {
    padding: 22px;
  }
}

@media (max-width: 991px) {
  .index-about .cont .cont1 .right {
    padding: 16px;
  }
}

@media (max-width: 767px) {
  .index-about .cont .cont1 .right {
    padding: 10px;
  }
}

@media (max-width: 1600px) {
  .index-about .cont .cont1 .right {
    padding-top: 26px;
  }
}

@media (max-width: 1359px) {
  .index-about .cont .cont1 .right {
    padding-top: 22px;
  }
}

@media (max-width: 1024px) {
  .index-about .cont .cont1 .right {
    padding-top: 18px;
  }
}

@media (max-width: 991px) {
  .index-about .cont .cont1 .right {
    padding-top: 14px;
  }
}

@media (max-width: 767px) {
  .index-about .cont .cont1 .right {
    padding-top: 10px;
  }
}

@media (max-width: 767px) {
  .index-about .cont .cont1 .right {
    width: 100%;
  }
}

.index-about .cont .cont1 .right .desc {
  font-family: 'Lato-Light';
  color: #333333;
  transition: all 0.2s;
  line-height: 1.1;
  margin-bottom: 20px;
}

@media (max-width: 1600px) {
  .index-about .cont .cont1 .right .desc {
    margin-bottom: 18px;
  }
}

@media (max-width: 1359px) {
  .index-about .cont .cont1 .right .desc {
    margin-bottom: 16px;
  }
}

@media (max-width: 1024px) {
  .index-about .cont .cont1 .right .desc {
    margin-bottom: 14px;
  }
}

@media (max-width: 991px) {
  .index-about .cont .cont1 .right .desc {
    margin-bottom: 12px;
  }
}

@media (max-width: 767px) {
  .index-about .cont .cont1 .right .desc {
    margin-bottom: 10px;
  }
}

.index-about .cont .cont1 .right .title {
  font-family: 'Lato-Light';
  line-height: 1.2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  margin-bottom: 25px;
  font-size: 30px;
}

@media (max-width: 1600px) {
  .index-about .cont .cont1 .right .title {
    margin-bottom: 22px;
  }
}

@media (max-width: 1359px) {
  .index-about .cont .cont1 .right .title {
    margin-bottom: 19px;
  }
}

@media (max-width: 1024px) {
  .index-about .cont .cont1 .right .title {
    margin-bottom: 16px;
  }
}

@media (max-width: 991px) {
  .index-about .cont .cont1 .right .title {
    margin-bottom: 13px;
  }
}

@media (max-width: 767px) {
  .index-about .cont .cont1 .right .title {
    margin-bottom: 10px;
  }
}

@media (max-width: 1600px) {
  .index-about .cont .cont1 .right .title {
    font-size: 26px;
  }
}

@media (max-width: 1359px) {
  .index-about .cont .cont1 .right .title {
    font-size: 22px;
  }
}

@media (max-width: 1024px) {
  .index-about .cont .cont1 .right .title {
    font-size: 18px;
  }
}

.index-about .cont .cont1 .right .link {
  border-top: 1px dashed #cccccc;
}

.index-about .cont .cont1 .right .link a {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: 'Lato-Bold';
  color: #469fdd;
  text-transform: uppercase;
  font-size: 14px;
  padding-top: 15px;
  padding-bottom: 15px;
}

@media (max-width: 1600px) {
  .index-about .cont .cont1 .right .link a {
    padding-top: 14px;
  }
}

@media (max-width: 1359px) {
  .index-about .cont .cont1 .right .link a {
    padding-top: 13px;
  }
}

@media (max-width: 1024px) {
  .index-about .cont .cont1 .right .link a {
    padding-top: 12px;
  }
}

@media (max-width: 991px) {
  .index-about .cont .cont1 .right .link a {
    padding-top: 11px;
  }
}

@media (max-width: 767px) {
  .index-about .cont .cont1 .right .link a {
    padding-top: 10px;
  }
}

@media (max-width: 1600px) {
  .index-about .cont .cont1 .right .link a {
    padding-bottom: 14px;
  }
}

@media (max-width: 1359px) {
  .index-about .cont .cont1 .right .link a {
    padding-bottom: 13px;
  }
}

@media (max-width: 1024px) {
  .index-about .cont .cont1 .right .link a {
    padding-bottom: 12px;
  }
}

@media (max-width: 991px) {
  .index-about .cont .cont1 .right .link a {
    padding-bottom: 11px;
  }
}

@media (max-width: 767px) {
  .index-about .cont .cont1 .right .link a {
    padding-bottom: 10px;
  }
}

.index-about .cont .cont1 .right .link a .iconfont {
  transition: transform 0.2s;
  font-size: 18px;
}

@media (max-width: 1600px) {
  .index-about .cont .cont1 .right .link a .iconfont {
    font-size: 17.2px;
  }
}

@media (max-width: 1359px) {
  .index-about .cont .cont1 .right .link a .iconfont {
    font-size: 16.4px;
  }
}

@media (max-width: 1024px) {
  .index-about .cont .cont1 .right .link a .iconfont {
    font-size: 15.6px;
  }
}

@media (max-width: 991px) {
  .index-about .cont .cont1 .right .link a .iconfont {
    font-size: 14.8px;
  }
}

@media (max-width: 767px) {
  .index-about .cont .cont1 .right .link a .iconfont {
    font-size: 14px;
  }
}

.index-about .cont .cont1 .right .link a:hover .iconfont {
  transform: rotate(90deg);
}

.index-about .cont .cont2 {
  position: relative;
  z-index: 1;
}

.index-about .cont .cont2 img {
  display: block;
  margin: 0 auto;
  width: 100%;
  height: auto;
  max-width: 1066px;
}

.index-contact {
  position: relative;
  color: #fff;
}

.index-contact::before {
  content: '';
  display: block;
  width: 60%;
  height: 60%;
  background-color: rgba(70, 159, 221, 0.24);
  position: absolute;
  top: 20%;
  left: 0;
  z-index: 0;
  border-top-right-radius: 250px;
  border-bottom-right-radius: 250px;
}

.index-contact::after {
  content: '';
  display: block;
  height: 52%;
  background-color: rgba(70, 159, 221, 0.86);
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 0;
  border-top-left-radius: 250px;
  border-bottom-left-radius: 250px;
  width: 50%;
}

@media (max-width: 767px) {
  .index-contact::after {
    width: 100%;
  }
}

.index-contact .cont {
  height: 100%;
  align-items: flex-start;
  justify-content: space-between;
  position: relative;
  z-index: 1;
  display: flex;
}

@media (max-width: 767px) {
  .index-contact .cont {
    display: block;
  }
}

.index-contact .cont .left {
  flex-shrink: 0;
  display: block;
  width: 47%;
}

@media (max-width: 767px) {
  .index-contact .cont .left {
    display: none;
  }
}

.index-contact .cont .left ul {
  display: flex;
  flex-wrap: wrap;
}

.index-contact .cont .left ul li {
  width: 50%;
  margin-bottom: 45px;
}

@media (max-width: 767px) {
  .index-contact .cont .left ul li {
    width: 100%;
  }
}

@media (max-width: 1600px) {
  .index-contact .cont .left ul li {
    margin-bottom: 39px;
  }
}

@media (max-width: 1359px) {
  .index-contact .cont .left ul li {
    margin-bottom: 33px;
  }
}

@media (max-width: 1024px) {
  .index-contact .cont .left ul li {
    margin-bottom: 27px;
  }
}

@media (max-width: 991px) {
  .index-contact .cont .left ul li {
    margin-bottom: 21px;
  }
}

@media (max-width: 767px) {
  .index-contact .cont .left ul li {
    margin-bottom: 15px;
  }
}

.index-contact .cont .left ul li .menu-title {
  font-family: 'Lato-Light';
  font-size: 30px;
  margin-bottom: 25px;
}

@media (max-width: 1600px) {
  .index-contact .cont .left ul li .menu-title {
    font-size: 26px;
  }
}

@media (max-width: 1359px) {
  .index-contact .cont .left ul li .menu-title {
    font-size: 22px;
  }
}

@media (max-width: 1024px) {
  .index-contact .cont .left ul li .menu-title {
    font-size: 18px;
  }
}

@media (max-width: 1600px) {
  .index-contact .cont .left ul li .menu-title {
    margin-bottom: 22px;
  }
}

@media (max-width: 1359px) {
  .index-contact .cont .left ul li .menu-title {
    margin-bottom: 19px;
  }
}

@media (max-width: 1024px) {
  .index-contact .cont .left ul li .menu-title {
    margin-bottom: 16px;
  }
}

@media (max-width: 991px) {
  .index-contact .cont .left ul li .menu-title {
    margin-bottom: 13px;
  }
}

@media (max-width: 767px) {
  .index-contact .cont .left ul li .menu-title {
    margin-bottom: 10px;
  }
}

.index-contact .cont .left ul li .sub-menu {
  line-height: 1.6;
}

.index-contact .cont .left ul li .sub-menu a {
  display: block;
  font-family: 'Lato-Bold';
  transition: all 0.2s;
  font-size: 18px;
}

@media (max-width: 1600px) {
  .index-contact .cont .left ul li .sub-menu a {
    font-size: 16px;
  }
}

@media (max-width: 1359px) {
  .index-contact .cont .left ul li .sub-menu a {
    font-size: 14px;
  }
}

.index-contact .cont .left ul li .sub-menu a:hover {
  margin-left: 5px;
}

.index-contact .cont .right {
  flex-shrink: 0;
  width: 50%;
}

@media (max-width: 767px) {
  .index-contact .cont .right {
    width: 100%;
  }
}

.index-contact .cont .right .logo-img {
  width: 100%;
  height: auto;
  position: relative;
  right: -75px;
  margin-top: 20px;
  margin-bottom: 20px;
}

@media (max-width: 1600px) {
  .index-contact .cont .right .logo-img {
    right: -62px;
  }
}

@media (max-width: 1359px) {
  .index-contact .cont .right .logo-img {
    right: -49px;
  }
}

@media (max-width: 1024px) {
  .index-contact .cont .right .logo-img {
    right: -36px;
  }
}

@media (max-width: 991px) {
  .index-contact .cont .right .logo-img {
    right: -23px;
  }
}

@media (max-width: 767px) {
  .index-contact .cont .right .logo-img {
    right: -10px;
  }
}

@media (max-width: 1600px) {
  .index-contact .cont .right .logo-img {
    margin-top: 18px;
  }
}

@media (max-width: 1359px) {
  .index-contact .cont .right .logo-img {
    margin-top: 16px;
  }
}

@media (max-width: 1024px) {
  .index-contact .cont .right .logo-img {
    margin-top: 14px;
  }
}

@media (max-width: 991px) {
  .index-contact .cont .right .logo-img {
    margin-top: 12px;
  }
}

@media (max-width: 767px) {
  .index-contact .cont .right .logo-img {
    margin-top: 10px;
  }
}

@media (max-width: 1600px) {
  .index-contact .cont .right .logo-img {
    margin-bottom: 18px;
  }
}

@media (max-width: 1359px) {
  .index-contact .cont .right .logo-img {
    margin-bottom: 16px;
  }
}

@media (max-width: 1024px) {
  .index-contact .cont .right .logo-img {
    margin-bottom: 14px;
  }
}

@media (max-width: 991px) {
  .index-contact .cont .right .logo-img {
    margin-bottom: 12px;
  }
}

@media (max-width: 767px) {
  .index-contact .cont .right .logo-img {
    margin-bottom: 10px;
  }
}

.index-contact .cont .right .language {
  float: right;
  background: #333a71;
  border-radius: 5px;
  position: relative;
  z-index: 2;
  display: block;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
}

@media (max-width: 1600px) {
  .index-contact .cont .right .language {
    padding-left: 14px;
  }
}

@media (max-width: 1359px) {
  .index-contact .cont .right .language {
    padding-left: 13px;
  }
}

@media (max-width: 1024px) {
  .index-contact .cont .right .language {
    padding-left: 12px;
  }
}

@media (max-width: 991px) {
  .index-contact .cont .right .language {
    padding-left: 11px;
  }
}

@media (max-width: 767px) {
  .index-contact .cont .right .language {
    padding-left: 10px;
  }
}

@media (max-width: 1600px) {
  .index-contact .cont .right .language {
    padding-right: 14px;
  }
}

@media (max-width: 1359px) {
  .index-contact .cont .right .language {
    padding-right: 13px;
  }
}

@media (max-width: 1024px) {
  .index-contact .cont .right .language {
    padding-right: 12px;
  }
}

@media (max-width: 991px) {
  .index-contact .cont .right .language {
    padding-right: 11px;
  }
}

@media (max-width: 767px) {
  .index-contact .cont .right .language {
    padding-right: 10px;
  }
}

@media (max-width: 1600px) {
  .index-contact .cont .right .language {
    padding-top: 5px;
  }
}

@media (max-width: 1359px) {
  .index-contact .cont .right .language {
    padding-top: 5px;
  }
}

@media (max-width: 1024px) {
  .index-contact .cont .right .language {
    padding-top: 5px;
  }
}

@media (max-width: 991px) {
  .index-contact .cont .right .language {
    padding-top: 5px;
  }
}

@media (max-width: 767px) {
  .index-contact .cont .right .language {
    padding-top: 5px;
  }
}

@media (max-width: 1600px) {
  .index-contact .cont .right .language {
    padding-bottom: 5px;
  }
}

@media (max-width: 1359px) {
  .index-contact .cont .right .language {
    padding-bottom: 5px;
  }
}

@media (max-width: 1024px) {
  .index-contact .cont .right .language {
    padding-bottom: 5px;
  }
}

@media (max-width: 991px) {
  .index-contact .cont .right .language {
    padding-bottom: 5px;
  }
}

@media (max-width: 767px) {
  .index-contact .cont .right .language {
    padding-bottom: 5px;
  }
}

.index-contact .cont .right .language:hover {
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
}

.index-contact .cont .right .language:hover .sub-language {
  opacity: 1;
  visibility: visible;
}

.index-contact .cont .right .language .icon-yuyan {
  font-size: 22px;
}

@media (max-width: 1600px) {
  .index-contact .cont .right .language .icon-yuyan {
    font-size: 20.8px;
  }
}

@media (max-width: 1359px) {
  .index-contact .cont .right .language .icon-yuyan {
    font-size: 19.6px;
  }
}

@media (max-width: 1024px) {
  .index-contact .cont .right .language .icon-yuyan {
    font-size: 18.4px;
  }
}

@media (max-width: 991px) {
  .index-contact .cont .right .language .icon-yuyan {
    font-size: 17.2px;
  }
}

@media (max-width: 767px) {
  .index-contact .cont .right .language .icon-yuyan {
    font-size: 16px;
  }
}

.index-contact .cont .right .language span {
  font-family: 'Lato-Bold';
  font-size: 18px;
  margin-left: 10px;
  margin-right: 20px;
}

@media (max-width: 1600px) {
  .index-contact .cont .right .language span {
    font-size: 16px;
  }
}

@media (max-width: 1359px) {
  .index-contact .cont .right .language span {
    font-size: 14px;
  }
}

@media (max-width: 1600px) {
  .index-contact .cont .right .language span {
    margin-left: 10px;
  }
}

@media (max-width: 1359px) {
  .index-contact .cont .right .language span {
    margin-left: 10px;
  }
}

@media (max-width: 1024px) {
  .index-contact .cont .right .language span {
    margin-left: 10px;
  }
}

@media (max-width: 991px) {
  .index-contact .cont .right .language span {
    margin-left: 10px;
  }
}

@media (max-width: 767px) {
  .index-contact .cont .right .language span {
    margin-left: 10px;
  }
}

@media (max-width: 1600px) {
  .index-contact .cont .right .language span {
    margin-right: 18px;
  }
}

@media (max-width: 1359px) {
  .index-contact .cont .right .language span {
    margin-right: 16px;
  }
}

@media (max-width: 1024px) {
  .index-contact .cont .right .language span {
    margin-right: 14px;
  }
}

@media (max-width: 991px) {
  .index-contact .cont .right .language span {
    margin-right: 12px;
  }
}

@media (max-width: 767px) {
  .index-contact .cont .right .language span {
    margin-right: 10px;
  }
}

.index-contact .cont .right .language .sub-language {
  position: absolute;
  top: 100%;
  right: 0;
  width: 100%;
  background: #333a71;
  text-align: center;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  overflow: hidden;
  opacity: 0;
  visibility: hidden;
  transition: all 0.2s;
  font-size: 18px;
}

@media (max-width: 1600px) {
  .index-contact .cont .right .language .sub-language {
    font-size: 16px;
  }
}

@media (max-width: 1359px) {
  .index-contact .cont .right .language .sub-language {
    font-size: 14px;
  }
}

.index-contact .cont .right .language .sub-language a {
  display: block;
  transition: all 0.2s;
  padding-top: 10px;
  padding-bottom: 10px;
}

@media (max-width: 1600px) {
  .index-contact .cont .right .language .sub-language a {
    padding-top: 9px;
  }
}

@media (max-width: 1359px) {
  .index-contact .cont .right .language .sub-language a {
    padding-top: 8px;
  }
}

@media (max-width: 1024px) {
  .index-contact .cont .right .language .sub-language a {
    padding-top: 7px;
  }
}

@media (max-width: 991px) {
  .index-contact .cont .right .language .sub-language a {
    padding-top: 6px;
  }
}

@media (max-width: 767px) {
  .index-contact .cont .right .language .sub-language a {
    padding-top: 5px;
  }
}

@media (max-width: 1600px) {
  .index-contact .cont .right .language .sub-language a {
    padding-bottom: 9px;
  }
}

@media (max-width: 1359px) {
  .index-contact .cont .right .language .sub-language a {
    padding-bottom: 8px;
  }
}

@media (max-width: 1024px) {
  .index-contact .cont .right .language .sub-language a {
    padding-bottom: 7px;
  }
}

@media (max-width: 991px) {
  .index-contact .cont .right .language .sub-language a {
    padding-bottom: 6px;
  }
}

@media (max-width: 767px) {
  .index-contact .cont .right .language .sub-language a {
    padding-bottom: 5px;
  }
}

.index-contact .cont .right .language .sub-language a:hover {
  background: rgba(255, 255, 255, 0.5);
  color: #252d67;
}

.index-contact .cont .right .contact {
  position: absolute;
  bottom: 16%;
  z-index: 1;
  margin-left: 190px;
}

@media (max-width: 1600px) {
  .index-contact .cont .right .contact {
    margin-left: 162px;
  }
}

@media (max-width: 1359px) {
  .index-contact .cont .right .contact {
    margin-left: 134px;
  }
}

@media (max-width: 1024px) {
  .index-contact .cont .right .contact {
    margin-left: 106px;
  }
}

@media (max-width: 991px) {
  .index-contact .cont .right .contact {
    margin-left: 78px;
  }
}

@media (max-width: 767px) {
  .index-contact .cont .right .contact {
    margin-left: 50px;
  }
}

@media screen and (max-width: 1600px) {
  .index-contact .cont .right .contact {
    bottom: 7%;
    margin-left: 100px;
  }
}

@media screen and (max-width: 1600px) and (max-width: 1600px) {
  .index-contact .cont .right .contact {
    margin-left: 90px;
  }
}

@media screen and (max-width: 1600px) and (max-width: 1359px) {
  .index-contact .cont .right .contact {
    margin-left: 80px;
  }
}

@media screen and (max-width: 1600px) and (max-width: 1024px) {
  .index-contact .cont .right .contact {
    margin-left: 70px;
  }
}

@media screen and (max-width: 1600px) and (max-width: 991px) {
  .index-contact .cont .right .contact {
    margin-left: 60px;
  }
}

@media screen and (max-width: 1600px) and (max-width: 767px) {
  .index-contact .cont .right .contact {
    margin-left: 50px;
  }
}

@media screen and (max-width: 767px) {
  .index-contact .cont .right .contact {
    margin-left: 10px;
  }
}

.index-contact .cont .right .contact .title {
  font-family: 'Lato-Bold';
  margin-bottom: 5px;
  font-size: 18px;
}

@media (max-width: 1600px) {
  .index-contact .cont .right .contact .title {
    font-size: 16px;
  }
}

@media (max-width: 1359px) {
  .index-contact .cont .right .contact .title {
    font-size: 14px;
  }
}

.index-contact .cont .right .contact .email {
  font-family: 'Lato-Light';
  margin-bottom: 45px;
  font-size: 22px;
}

@media (max-width: 1600px) {
  .index-contact .cont .right .contact .email {
    margin-bottom: 40px;
  }
}

@media (max-width: 1359px) {
  .index-contact .cont .right .contact .email {
    margin-bottom: 35px;
  }
}

@media (max-width: 1024px) {
  .index-contact .cont .right .contact .email {
    margin-bottom: 30px;
  }
}

@media (max-width: 991px) {
  .index-contact .cont .right .contact .email {
    margin-bottom: 25px;
  }
}

@media (max-width: 767px) {
  .index-contact .cont .right .contact .email {
    margin-bottom: 20px;
  }
}

@media (max-width: 1600px) {
  .index-contact .cont .right .contact .email {
    font-size: 20px;
  }
}

@media (max-width: 1359px) {
  .index-contact .cont .right .contact .email {
    font-size: 18px;
  }
}

@media (max-width: 1024px) {
  .index-contact .cont .right .contact .email {
    font-size: 16px;
  }
}

@media screen and (max-width: 1600px) {
  .index-contact .cont .right .contact .email {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 1600px) and (max-width: 1600px) {
  .index-contact .cont .right .contact .email {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 1600px) and (max-width: 1359px) {
  .index-contact .cont .right .contact .email {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 1600px) and (max-width: 1024px) {
  .index-contact .cont .right .contact .email {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 1600px) and (max-width: 991px) {
  .index-contact .cont .right .contact .email {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 1600px) and (max-width: 767px) {
  .index-contact .cont .right .contact .email {
    margin-bottom: 20px;
  }
}

.index-contact .cont .right .contact .tel {
  font-family: 'Lato-Light';
  line-height: 1.2;
  font-size: 36px;
  margin-bottom: 25px;
}

@media (max-width: 1600px) {
  .index-contact .cont .right .contact .tel {
    font-size: 32px;
  }
}

@media (max-width: 1359px) {
  .index-contact .cont .right .contact .tel {
    font-size: 28px;
  }
}

@media (max-width: 1024px) {
  .index-contact .cont .right .contact .tel {
    font-size: 20px;
  }
}

@media (max-width: 1600px) {
  .index-contact .cont .right .contact .tel {
    margin-bottom: 22px;
  }
}

@media (max-width: 1359px) {
  .index-contact .cont .right .contact .tel {
    margin-bottom: 19px;
  }
}

@media (max-width: 1024px) {
  .index-contact .cont .right .contact .tel {
    margin-bottom: 16px;
  }
}

@media (max-width: 991px) {
  .index-contact .cont .right .contact .tel {
    margin-bottom: 13px;
  }
}

@media (max-width: 767px) {
  .index-contact .cont .right .contact .tel {
    margin-bottom: 10px;
  }
}

.index-contact .cont .right .contact .link {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

@media (max-width: 767px) {
  .index-contact .cont .right .contact .link {
    justify-content: center;
  }
}

.index-contact .cont .right .contact .link a {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #fff;
  color: #252d67;
  transition: all 0.2s;
  width: 55px;
  height: 55px;
  margin-right: 25px;
}

@media (max-width: 1600px) {
  .index-contact .cont .right .contact .link a {
    width: 52px;
  }
}

@media (max-width: 1359px) {
  .index-contact .cont .right .contact .link a {
    width: 49px;
  }
}

@media (max-width: 1024px) {
  .index-contact .cont .right .contact .link a {
    width: 46px;
  }
}

@media (max-width: 991px) {
  .index-contact .cont .right .contact .link a {
    width: 43px;
  }
}

@media (max-width: 767px) {
  .index-contact .cont .right .contact .link a {
    width: 40px;
  }
}

@media (max-width: 1600px) {
  .index-contact .cont .right .contact .link a {
    height: 52px;
  }
}

@media (max-width: 1359px) {
  .index-contact .cont .right .contact .link a {
    height: 49px;
  }
}

@media (max-width: 1024px) {
  .index-contact .cont .right .contact .link a {
    height: 46px;
  }
}

@media (max-width: 991px) {
  .index-contact .cont .right .contact .link a {
    height: 43px;
  }
}

@media (max-width: 767px) {
  .index-contact .cont .right .contact .link a {
    height: 40px;
  }
}

@media (max-width: 1600px) {
  .index-contact .cont .right .contact .link a {
    margin-right: 23px;
  }
}

@media (max-width: 1359px) {
  .index-contact .cont .right .contact .link a {
    margin-right: 21px;
  }
}

@media (max-width: 1024px) {
  .index-contact .cont .right .contact .link a {
    margin-right: 19px;
  }
}

@media (max-width: 991px) {
  .index-contact .cont .right .contact .link a {
    margin-right: 17px;
  }
}

@media (max-width: 767px) {
  .index-contact .cont .right .contact .link a {
    margin-right: 15px;
  }
}

.index-contact .cont .right .contact .link a:last-child {
  margin-right: 0;
}

.index-contact .cont .right .contact .link a:hover {
  background-color: #252d67;
  color: #fff;
}

.index-contact .cont .right .contact .link a .iconfont {
  font-size: 24px;
}

@media (max-width: 1600px) {
  .index-contact .cont .right .contact .link a .iconfont {
    font-size: 22.8px;
  }
}

@media (max-width: 1359px) {
  .index-contact .cont .right .contact .link a .iconfont {
    font-size: 21.6px;
  }
}

@media (max-width: 1024px) {
  .index-contact .cont .right .contact .link a .iconfont {
    font-size: 20.4px;
  }
}

@media (max-width: 991px) {
  .index-contact .cont .right .contact .link a .iconfont {
    font-size: 19.2px;
  }
}

@media (max-width: 767px) {
  .index-contact .cont .right .contact .link a .iconfont {
    font-size: 18px;
  }
}
